import React from "react";
import { useNavigate } from "react-router-dom";
import "./ExploreSection.css"; // Separate CSS file for styles

const ExploreSection = () => {
    const navigate = useNavigate();

    return (
        <div className="gettingStart_container">
            <div className="details_container">
                <div className="get_Started_text1">EXPLORE THE NEW WAY Of LEARNING</div>
                <div className="get_Started_text2">
                    Ready to unlock your potential? Practice SSC PYQ questions online.
                </div>
                <button
                    style={{ color: "white" }}
                    className="start_button"
                    onClick={() => navigate("/quizCategory")}
                >
                    GET STARTED
                </button>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                    className="image_style"
                    src="https://img.freepik.com/premium-vector/woman-sitting-desk-focused-studying-with-laptop-open-stack-books-beside-her-studying-woman-graduate-succes-trending-simple-minimalist-flat-vector-illustration_538213-70175.jpg?semt=ais_hybrid"
                    alt="dimension"
                />
            </div>
        </div>
    );
};

export default ExploreSection;
