import quizzes from "./quizzes.json";
import explore from "./explore-more.json"
import polity from "./polity.json"
import economics from "./economics.json";
import history from "./history.json";
import geography from "./geography.json";
import current from "./current.json";
import physics from "./physics.json";
import chemistry from "./chemistry.json";
import biology from "./biology.json";

const allData = [
    ...quizzes.categories, biology, polity, economics, history, geography, current, physics, chemistry, explore
]

export default allData;
