//src/components/store/cart.js
// import React from "react";
// import { useNavigate } from "react-router-dom";

// const Cart = ({ cart }) => {
//     const navigate = useNavigate();
//     return (
//         <div style={{ padding: "20px" }}>
//             <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>Your Cart</h2>
//             {cart.length === 0 ? <p>No items in cart</p> : null}
//             {cart.map((item, index) => (
//                 <p key={index}>
//                     {item.name} - ₹{item.price}
//                 </p>
//             ))}
//             <button
//                 className="button n-button"
//                 style={{
//                     marginTop: "16px",
//                 }}
//                 onClick={() => navigate("/checkout")}
//             >
//                 Proceed to Checkout
//             </button>
//         </div>
//     );
// };

// export default Cart;

import React from "react";
import { useNavigate } from "react-router-dom";

const Cart = ({ cart, setCart }) => {
    const navigate = useNavigate();

    const removeFromCart = (index) => {
        const updatedCart = cart.filter((_, i) => i !== index);
        setCart(updatedCart);
    };



    return (
        <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
            <h2 style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", marginBottom: "20px" }}>
                Your Cart
            </h2>

            {cart.length === 0 ? (
                <p style={{ textAlign: "center", fontSize: "18px", color: "gray" }}>Your cart is empty.</p>
            ) : (
                <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                    {cart.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "12px",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                backgroundColor: "#fff",
                                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <div>
                                <p style={{ fontSize: "16px", fontWeight: "bold", margin: "0" }}>{item.name}</p>
                                <p style={{ margin: "0", color: "#555" }}>₹{item.price}</p>
                            </div>

                            <button
                                style={{
                                    backgroundColor: "#ff4d4d",
                                    color: "white",
                                    border: "none",
                                    padding: "6px 12px",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                }}
                                onClick={() => removeFromCart(index)}
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                </div>
            )}

            {cart.length > 0 && (
                <button
                    style={{
                        display: "block",
                        width: "100%",
                        marginTop: "20px",
                        backgroundColor: "blue",
                        color: "white",
                        padding: "10px",
                        borderRadius: "6px",
                        border: "none",
                        fontSize: "16px",
                        cursor: "pointer",
                        textAlign: "center",
                    }}
                    onClick={() => navigate("/checkout")}
                >
                    Proceed to Checkout
                </button>
            )}
        </div>
    );
};

export default Cart;
