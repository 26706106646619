// import React from "react";
// import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa"; // Import icons

// const ChatButtons = () => {
//     const buttonStyle = {
//         position: "fixed",
//         right: "20px",
//         zIndex: "1000",
//         padding: "10px",
//         borderRadius: "50%",
//         boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
//         transition: "transform 0.3s ease-in-out",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         animation: "bounce 1.5s infinite", // Bouncing animation
//     };

//     const whatsappStyle = {
//         ...buttonStyle,
//         bottom: "70px",
//         backgroundColor: "#25d366",
//     };

//     const telegramStyle = {
//         ...buttonStyle,
//         bottom: "130px", // Placed above WhatsApp
//         backgroundColor: "#0088cc",
//     };

//     const iconStyle = {
//         width: "30px",
//         height: "30px",
//         color: "white"
//     };

//     return (
//         <>
//             {/* Telegram Button */}
//             <a
//                 href="https://t.me/sscpyqQuestions" // Replace with your Telegram link
//                 style={telegramStyle}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
//                 onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
//             >
//                 <FaTelegramPlane style={iconStyle} />
//             </a>

//             {/* WhatsApp Button */}
//             <a
//                 href="https://whatsapp.com/channel/0029VayrxWv96H4M5AfWGJ46" // Replace with your WhatsApp link
//                 style={whatsappStyle}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
//                 onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
//             >
//                 <FaWhatsapp style={iconStyle} />
//             </a>
//         </>
//     );
// };

// export default ChatButtons;

import React, { useState } from "react";
import { FaWhatsapp, FaTelegramPlane, FaHandsHelping } from "react-icons/fa"; // Import icons
import Donation from "./Donation"; // Import Donation Component

const ChatButtons = () => {
    const [showDonation, setShowDonation] = useState(false);

    const buttonStyle = {
        position: "fixed",
        right: "20px",
        zIndex: "1000",
        padding: "10px",
        borderRadius: "50%",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.3s ease-in-out",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        animation: "bounce 1.5s infinite", // Bouncing animation
        cursor: "pointer",
    };

    const whatsappStyle = {
        ...buttonStyle,
        bottom: "70px",
        backgroundColor: "#25d366",
    };

    const telegramStyle = {
        ...buttonStyle,
        bottom: "130px",
        backgroundColor: "#0088cc",
    };

    const donateStyle = {
        ...buttonStyle,
        bottom: "190px", // Placed above Telegram
        backgroundColor: "#ff9800",
    };

    const iconStyle = {
        width: "30px",
        height: "30px",
        color: "white"
    };

    return (
        <>
            {/* Donation Button */}
            <div
                style={donateStyle}
                onClick={() => setShowDonation(true)}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
                <FaHandsHelping style={iconStyle} />
            </div>

            {/* Telegram Button */}
            <a
                href="https://t.me/sscpyqQuestions"
                style={telegramStyle}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
                <FaTelegramPlane style={iconStyle} />
            </a>

            {/* WhatsApp Button */}
            <a
                href="https://chat.whatsapp.com/Db22eP96EfRKCCqRCcidGu"
                style={whatsappStyle}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
                <FaWhatsapp style={iconStyle} />
            </a>

            {/* Show Donation Modal */}
            {showDonation && <Donation onClose={() => setShowDonation(false)} />}
        </>
    );
};

export default ChatButtons;