import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { load } from '@cashfreepayments/cashfree-js';
import axios from 'axios';
import './Donation.css'; // Import CSS for styling

const Donation = ({ onClose }) => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        donationAmount: "",
    });
    const [loading, setLoading] = useState(false);
    const [paymentSessionId, setPaymentSessionId] = useState(null);
    // const [orderId, setOrderId] = useState("");
    const [cashfree, setCashfree] = useState(null);
    const [showThankYou, setShowThankYou] = useState(false); // State for thank-you message

    useEffect(() => {
        const initializeCashfree = async () => {
            try {
                const cashfreeInstance = await load({ mode: "production" });
                setCashfree(cashfreeInstance);
            } catch (error) {
                alert("Failed to initialize payment gateway");
            }
        };
        initializeCashfree();
    }, []);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        if (!form.name || !form.email || !form.phone || !form.donationAmount) {
            alert("Please fill out all fields.");
            return false;
        }
        return true;
    };

    const createDonationInFirestore = async () => {
        try {
            const donationRef = await addDoc(collection(db, "donations"), {
                ...form,
                status: "pending",
                createdAt: new Date(),
            });
            return donationRef.id;
        } catch (error) {
            alert("Error creating donation.");
            return null;
        }
    };

    const createPaymentSession = async (orderId) => {
        try {
            setLoading(true);
            const response = await axios.post('https://cashfree-backend-1k3q.onrender.com/create-payment-session', {
                orderId,
                orderAmount: form.donationAmount,
                customerName: form.name,
                customerEmail: form.email,
                customerPhone: form.phone,
            });

            if (!response.data?.payment_session_id) throw new Error('Missing payment session ID');

            setPaymentSessionId(response.data.payment_session_id);
            await updateDoc(doc(db, "donations", orderId), { paymentSessionId: response.data.payment_session_id });

            setLoading(false);
            return response.data.payment_session_id;
        } catch (error) {
            alert("Payment processing failed");
            setLoading(false);
            throw error;
        }
    };

    const doPayment = async () => {
        try {
            if (!paymentSessionId) return;

            cashfree.checkout({ paymentSessionId, redirectTarget: "_modal" }).then((result) => {
                if (result.paymentDetails) {
                    setShowThankYou(true); // Show thank-you message after successful payment
                }
            });
        } catch (error) {
            alert("Payment failed.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const orderID = await createDonationInFirestore();
        if (orderID) {
            // setOrderId(orderID);
            const sessionId = await createPaymentSession(orderID);
            if (sessionId) setPaymentSessionId(sessionId);
        }
    };

    return (
        <>
            {/* Donation Modal */}
            <div className="modal-overlay">
                <div className="modal">
                    <h2>Please Join Us in Making a Difference</h2>
                    <p style={{ fontSize: "14px", color: "#555", marginTop: "10px" }}>
                        Every contribution, motivate us to bring more free educational content. Thank You!!❤️
                    </p>
                    <button onClick={onClose} className="close-button">X</button>
                    <form onSubmit={handleSubmit} className="donation-form">
                        <input name="name" placeholder="Name" required onChange={handleChange} />
                        <input name="email" type="email" placeholder="Email" required onChange={handleChange} />
                        <input name="phone" placeholder="Phone Number" required onChange={handleChange} />
                        <input name="donationAmount" type="number" placeholder="Donation Amount" required onChange={handleChange} />
                        {/* <button type="submit" disabled={loading} className="donate-button">
                            {loading ? "Processing..." : "Donate"}
                        </button> */}
                        <button
                            type="submit"
                            disabled={loading}
                            className={`donate-button ${loading ? "loading" : ""}`}
                        >
                            {loading ? "Processing..." : "Donate"}
                        </button>

                    </form>
                    {paymentSessionId && (
                        <button onClick={doPayment} className="complete-payment-button">
                            Complete Payment
                        </button>
                    )}
                </div>
            </div>

            {/* Thank You Modal */}
            {showThankYou && (
                <div className="modal-overlay">
                    <div className="modal thank-you-modal">
                        <h2>Thank You!</h2>
                        <p>Your support means the world to us. ❤️</p>
                        <button onClick={() => setShowThankYou(false)} className="close-button">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Donation;