import React from "react";
import { useNavigate } from "react-router-dom";

const InfoCard = ({ card, darkMode }) => {
    const navigate = useNavigate();

    return (
        <div
            onClick={() => navigate(card.link)}
            style={{
                cursor: 'pointer',
                border: '1px solid #ccc',
                borderRadius: '10px',
                width: 'clamp(250px, 30vw, 350px)', // Responsive width
                minHeight: '150px', // Minimum height to avoid layout shifts
                textAlign: 'center',
                padding: '20px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                position: 'relative',
                background: darkMode ? "black" : "rgb(243, 243, 243)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            }}
        >
            {/* Optional Blurred Background Image */}
            {/* <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${card.backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.2, // For readability
                    borderRadius: '10px',
                }}
            ></div> */}

            <h3 style={{ margin: '10px 0', fontWeight: "bolder", color: darkMode ? "white" : "black", position: "relative", zIndex: 2 }}>
                {card.title}
            </h3>
            <p style={{ color: darkMode ? "white" : "#666", position: "relative", zIndex: 2 }}>
                {card.description}
            </p>
        </div>
    );
};

export default InfoCard;
