import React from "react";

const ContactUs = () => {
    const styles = {
        container: {
            fontFamily: "Arial, sans-serif",
            maxWidth: "600px",
            margin: "40px auto",
            padding: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            boxShadow: "2px 2px 12px rgba(0,0,0,0.1)"
        },
        heading: {
            textAlign: "center",
            color: "#333"
        },
        updatedText: {
            fontSize: "14px",
            color: "#555",
            marginBottom: "15px",
            textAlign: "center"
        },
        infoBlock: {
            marginBottom: "15px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: "#fff",
            border: "1px solid #ddd"
        },
        label: {
            fontWeight: "bold",
            color: "#222"
        },
        value: {
            color: "#444"
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Contact Us</h2>
            <p style={styles.updatedText}>
                Last updated on <strong>22-02-2025 12:39:41</strong>
            </p>

            <div style={styles.infoBlock}>
                <p>
                    <span style={styles.label}>Merchant Legal Entity Name:</span> <span style={styles.value}>SUMAN SAURAV</span>
                </p>
                <p>
                    <span style={styles.label}>Registered Address:</span> <span style={styles.value}>In Front Of Post Office, Piru, Bihar, PIN: 802207</span>
                </p>
                <p>
                    <span style={styles.label}>Operational Address:</span> <span style={styles.value}>In Front Of Post Office, Piru, Bihar, PIN: 802207</span>
                </p>
                <p>
                    <span style={styles.label}>Telephone No:</span> <span style={styles.value}>9939720421</span>
                </p>
                <p>
                    <span style={styles.label}>E-Mail ID:</span> <span style={styles.value}>sscvidya.in@gmail.com</span>
                </p>
            </div>
        </div>
    );
};

export default ContactUs;
