// import React, { useState, useEffect } from "react";
// import { db, auth } from "../../firebase/firebaseConfig";
// import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
// import { load } from "@cashfreepayments/cashfree-js";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const EbookCheckout = ({ cart }) => {
//     const [form, setForm] = useState({
//         name: "",
//         email: "",
//         phone: "",
//         address: "",
//     });

//     const [loading, setLoading] = useState(false);
//     const [paymentSessionId, setPaymentSessionId] = useState(null);
//     const [orderId, setOrderId] = useState("");
//     const [cashfree, setCashfree] = useState(null);
//     const navigate = useNavigate();

//     // ✅ Initialize Cashfree SDK
//     useEffect(() => {
//         const initializeCashfree = async () => {
//             try {
//                 const cashfreeInstance = await load({ mode: "production" });
//                 setCashfree(cashfreeInstance);
//             } catch (error) {
//                 console.error("Error initializing Cashfree:", error);
//                 alert("Failed to initialize payment gateway");
//             }
//         };
//         initializeCashfree();
//     }, []);

//     // ✅ Handle form input changes
//     const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

//     // ✅ Validate form
//     const validateForm = () => {
//         if (!form.name || !form.email || !form.phone || !form.address) {
//             alert("Please fill out all required fields.");
//             return false;
//         }
//         return true;
//     };

//     // ✅ Create an order in Firestore
//     // const createOrderInFirestore = async () => {
//     //     try {
//     //         const totalAmount = cart.reduce((sum, item) => sum + item.price, 0);
//     //         const orderRef = await addDoc(collection(db, "orders"), {
//     //             ...form,
//     //             cart,
//     //             totalAmount,
//     //             status: "pending",
//     //             createdAt: new Date(),
//     //         });
//     //         return orderRef.id;
//     //     } catch (error) {
//     //         console.error("Error storing order:", error);
//     //         alert("Error creating order. Please try again.");
//     //         return null;
//     //     }
//     // };
//     const createOrderInFirestore = async () => {
//         try {
//             const user = auth.currentUser;
//             if (!user) {
//                 alert("User not logged in! Please log in to place an order.");
//                 return null;
//             }

//             const totalAmount = cart.reduce((sum, item) => sum + item.price, 0);

//             // ✅ Save order inside user's collection
//             const orderRef = await addDoc(collection(db, "users", user.uid, "orders"), {
//                 userId: user.uid, // Ensure it's linked to the correct user
//                 ...form,
//                 cart,
//                 totalAmount,
//                 status: "pending",
//                 createdAt: new Date(),
//             });

//             return orderRef.id; // Return order ID to track transaction
//         } catch (error) {
//             console.error("Error storing order:", error);
//             alert("Error creating order. Please try again.");
//             return null;
//         }
//     };


//     // ✅ Create Cashfree Payment Session
//     const createPaymentSession = async (orderId) => {
//         try {
//             setLoading(true);
//             const response = await axios.post('https://cashfree-backend-1k3q.onrender.com/create-payment-session', {
//                 orderId,
//                 orderAmount: cart.reduce((sum, item) => sum + item.price, 0),
//                 customerName: form.name,
//                 customerEmail: form.email,
//                 customerPhone: form.phone,
//             });

//             if (!response.data?.payment_session_id) {
//                 throw new Error('Payment session ID is missing from response');
//             }

//             setPaymentSessionId(response.data.payment_session_id);
//             await updateDoc(doc(db, "orders", orderId), { paymentSessionId: response.data.payment_session_id });

//             setLoading(false);
//             return response.data.payment_session_id;
//         } catch (error) {
//             console.error("Error creating payment session:", error);
//             alert("Payment processing failed");
//             setLoading(false);
//             throw error;
//         }
//     };

//     // ✅ Verify Payment After Completion
//     const verifyPayment = async (orderId) => {
//         try {
//             const res = await axios.get(`https://cashfree-backend-1k3q.onrender.com/payment-status/${orderId}`);
//             if (res.data && res.data.length > 0 && res.data[0].payment_status === "SUCCESS") {
//                 alert("Payment verified ✅");

//                 // 🔥 Store Download Links in Firestore
//                 await updateDoc(doc(db, "orders", orderId), {
//                     status: "paid",
//                     downloads: cart.map((item) => ({
//                         name: item.name,
//                         downloadLink: item.downloadLink,
//                     })),
//                 });
//                 localStorage.removeItem("orderId"); // Clear Order ID after successful payment
//                 navigate("/downloads"); // Redirect to My Downloads Page
//             } else {
//                 alert("Payment verification failed");
//             }
//         } catch (error) {
//             console.error("Error verifying payment:", error);
//             alert("Failed to verify payment");
//         }
//     };

//     // ✅ Process Payment
//     const doPayment = async () => {
//         try {
//             if (!paymentSessionId) {
//                 console.log("Payment session ID not ready yet");
//                 return;
//             }

//             const checkoutOptions = {
//                 paymentSessionId,
//                 redirectTarget: "_modal",
//             };

//             cashfree.checkout(checkoutOptions).then((result) => {
//                 if (result.paymentDetails) {
//                     verifyPayment(orderId);
//                 }
//             });
//         } catch (error) {
//             console.error("Error processing payment:", error);
//             alert("Payment processing failed");
//         }
//     };

//     // ✅ Handle Checkout Form Submission
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!validateForm()) return;

//         const orderID = await createOrderInFirestore();
//         if (orderID) {
//             setOrderId(orderID);
//             const sessionId = await createPaymentSession(orderID);
//             if (sessionId) {
//                 setPaymentSessionId(sessionId);
//             }
//         }
//     };

//     return (
//         <div style={{ padding: "20px" }}>
//             <h2 style={{ display: "flex", justifyContent: "center" }}>Checkout 🚀</h2>
//             <form onSubmit={handleSubmit} style={{ display: "grid", gap: "12px" }}>
//                 <input name="name" placeholder="Name" required onChange={handleChange} style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
//                 />
//                 <input name="email" type="email" placeholder="Email" required onChange={handleChange} style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
//                 />
//                 <input name="phone" placeholder="Phone Number" required onChange={handleChange} style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
//                 />
//                 <input name="address" placeholder="Address" required onChange={handleChange} style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
//                 />

//                 <button type="submit" disabled={loading} className="button n-button">
//                     {loading ? "Processing... Please Wait!!" : "Submit Details"}
//                 </button>
//             </form>

//             {paymentSessionId && (
//                 <button onClick={doPayment} style={{ marginTop: "20px" }}>
//                     Pay Now
//                 </button>
//             )}
//         </div>
//     );
// };

// export default EbookCheckout;

import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase/firebaseConfig";
import { setDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { load } from "@cashfreepayments/cashfree-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // For generating unique order IDs
import { ClipLoader } from "react-spinners";

const EbookCheckout = ({ cart }) => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
    });

    const [loading, setLoading] = useState(false);
    const [paymentSessionId, setPaymentSessionId] = useState(null);
    // const [orderId, setOrderId] = useState("");
    const [cashfree, setCashfree] = useState(null);
    const [detailsSubmitted, setDetailsSubmitted] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null); // null, 'pending', 'success'

    const navigate = useNavigate();

    // ✅ Initialize Cashfree SDK
    useEffect(() => {
        const initializeCashfree = async () => {
            try {
                const cashfreeInstance = await load({ mode: "production" });
                setCashfree(cashfreeInstance);
            } catch (error) {
                console.error("Error initializing Cashfree:", error);
                alert("Failed to initialize payment gateway");
            }
        };
        initializeCashfree();
    }, []);


    // ✅ Handle form input changes
    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

    // ✅ Validate form
    const validateForm = () => {
        const { name, email, phone, address } = form;

        // Validate Name
        if (!name || name.trim().length < 2) {
            alert("Please enter a valid name (at least 2 characters).");
            return false;
        }

        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            alert("Please enter a valid email address.");
            return false;
        }

        // Validate Phone Number
        const phoneRegex = /^\d{10}$/; // Assumes a 10-digit phone number
        if (!phone || !phoneRegex.test(phone)) {
            alert("Please enter a valid 10-digit phone number.");
            return false;
        }

        // Validate Address
        if (!address || address.trim().length < 5) {
            alert("Please enter a valid address (at least 7 characters).");
            return false;
        }

        return true; // All validations passed
    };

    // ✅ Save order to Firestore (nested in user document)
    const saveOrder = async (userId, orderData) => {
        try {
            const userDocRef = doc(db, "users", userId);
            const userDoc = await getDoc(userDocRef);

            const orderId = `order_${uuidv4()}`; // Generate a unique order ID

            if (userDoc.exists()) {
                // If user data exists, update orders
                await updateDoc(userDocRef, {
                    [`orders.${orderId}`]: {
                        ...orderData,
                        orderId,
                        createdAt: new Date(),
                        status: "pending", // Initial status
                    },
                });
            } else {
                // If user data doesn't exist, create new data
                await setDoc(userDocRef, {
                    orders: {
                        [orderId]: {
                            ...orderData,
                            orderId,
                            createdAt: new Date(),
                            status: "pending",
                        },
                    },
                });
            }

            console.log("Order saved successfully!");
            return orderId; // Return the order ID for further processing
        } catch (error) {
            console.error("Error saving order: ", error);
            throw error;
        }
    };

    // ✅ Create an order in Firestore
    const createOrderInFirestore = async () => {
        try {
            const user = auth.currentUser;
            if (!user) {
                alert("User not logged in! Please log in to place an order.");
                return null;
            }

            const totalAmount = cart.reduce((sum, item) => sum + item.price, 0);
            const orderData = {
                userId: user.uid,
                ...form, // Customer details (name, email, phone, address)
                cart,
                totalAmount,
            };

            const orderId = await saveOrder(user.uid, orderData); // Save the order
            return orderId;
        } catch (error) {
            console.error("Error storing order:", error);
            alert("Error creating order. Please try again.");
            return null;
        }
    };

    // ✅ Create Cashfree Payment Session
    const createPaymentSession = async (orderId) => {
        try {
            setLoading(true);
            const response = await axios.post('https://cashfree-backend-1k3q.onrender.com/create-payment-session', {
                orderId,
                orderAmount: cart.reduce((sum, item) => sum + item.price, 0),
                customerName: form.name,
                customerEmail: form.email,
                customerPhone: form.phone,
            });

            if (!response.data?.payment_session_id) {
                throw new Error('Payment session ID is missing from response');
            }

            setPaymentSessionId(response.data.payment_session_id);
            await updateDoc(doc(db, "users", auth.currentUser.uid), {
                [`orders.${orderId}.paymentSessionId`]: response.data.payment_session_id,
            });

            setLoading(false);
            return response.data.payment_session_id;
        } catch (error) {
            console.error("Error creating payment session:", error);
            alert("Payment processing failed");
            setLoading(false);
            throw error;
        }
    };


    // ✅ Verify Payment After Completion
    // const verifyPayment = async (orderId) => {
    //     try {
    //         const res = await axios.get(`https://cashfree-backend-1k3q.onrender.com/payment-status/${orderId}`);
    //         if (res.data && res.data.length > 0 && res.data[0].payment_status === "SUCCESS") {

    //             // Update order status and add download links
    //             await updateDoc(doc(db, "users", auth.currentUser.uid), {
    //                 [`orders.${orderId}.status`]: "paid",
    //                 [`orders.${orderId}.downloads`]: cart.map((item) => ({
    //                     name: item.name,
    //                     downloadLink: item.downloadLink,
    //                 })),
    //             });
    //             alert("Your files have been successfully added. Please Refresh.✅");
    //             localStorage.removeItem("orderId"); // Clear Order ID after successful payment
    //             // setVerificationLoading(false); // Stop verification loader
    //             // navigate("/downloads"); // Redirect to My Downloads Page
    //         } else {
    //             alert("Payment verification failed");
    //         }
    //     } catch (error) {
    //         console.error("Error verifying payment:", error);
    //         alert("Failed to verify payment");
    //     }
    // };


    // ✅ Process Payment
    const doPayment = async () => {
        try {
            if (!paymentSessionId) {
                console.log("Payment session ID not ready yet");
                return;
            }
            //Check if /echeckout page then it run function that alert don't so user don't navigates from page.
            const checkoutOptions = {
                paymentSessionId,
                redirectTarget: "_modal",
            };

            cashfree.checkout(checkoutOptions).then((result) => {
                setPaymentStatus('success');
                if (result.paymentDetails) {
                    navigate("/downloads"); // Redirect to downloads page after verification
                    // alert("Adding your files. Please stay on the page. ✅");
                    // verifyPayment(orderId);
                }
            });
        } catch (error) {
            console.error("Error processing payment:", error);
            alert("Payment processing failed");
        }
    };

    // ✅ Handle Checkout Form Submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setDetailsSubmitted(true); // Change button text after submission
        const orderID = await createOrderInFirestore();
        if (orderID) {
            // setOrderId(orderID);
            const sessionId = await createPaymentSession(orderID);
            if (sessionId) {
                setPaymentSessionId(sessionId);
            }
        }
    };

    // Payment success UI
    if (paymentStatus === 'success') {
        return (
            <div style={{
                padding: "40px 20px",
                textAlign: "center",
                maxWidth: "600px",
                margin: "0 auto"
            }}>
                <div style={{
                    background: "#f0fff0",
                    padding: "30px",
                    borderRadius: "10px",
                    border: "1px solid #d4edda"
                }}>
                    <h4 style={{ color: "#28a745", marginBottom: "20px" }}>If Payment is Successful! ✅</h4>
                    <p style={{ fontSize: "18px", marginBottom: "15px" }}>
                        You can find your ebooks in downloads!
                    </p>
                    <button
                        onClick={() => navigate("/downloads")}
                        style={{
                            backgroundColor: "#28a745",
                            color: "white",
                            padding: "12px 24px",
                            borderRadius: "6px",
                            border: "none",
                            fontSize: "16px",
                            cursor: "pointer",
                            fontWeight: "bold"
                        }}
                    >
                        View My Downloads
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div style={{ padding: "20px", marginBottom: "50vh" }}>
            <h2 style={{ display: "flex", justifyContent: "center" }}>Checkout 🚀</h2>

            <form onSubmit={handleSubmit} style={{ display: "grid", gap: "12px" }}>
                <input
                    name="name"
                    placeholder="Name"
                    required
                    onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                    onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="phone"
                    placeholder="Phone Number"
                    required
                    onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="address"
                    placeholder="Address"
                    required
                    onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />

                <button type="submit" disabled={loading || detailsSubmitted} style={{
                    display: "block",
                    width: "100%",
                    marginTop: "20px",
                    backgroundColor: "blue",
                    color: "white",
                    padding: "10px",
                    borderRadius: "6px",
                    border: "none",
                    fontSize: "16px",
                    cursor: detailsSubmitted ? "not-allowed" : "pointer",
                    textAlign: "center",
                }}>
                    {loading ? (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <ClipLoader size={20} color="#ffffff" /> Processing... Please Wait..
                        </div>
                    ) : (
                        `${detailsSubmitted ? "Details Submitted" : "Submit"}`
                    )}
                </button>
                {loading ? (<span>Your payment is in progress. Sometimes it takes a little time. Thank you for your patience! ⏱️</span>) : ""}
            </form>

            {paymentSessionId && (
                <button onClick={doPayment} style={{ marginTop: "20px" }} className="button n-button">
                    Pay Now
                </button>
            )}
            <div
                style={{
                    position: "fixed",
                    bottom: "15px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "90%",
                    maxWidth: "400px",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                }}
            >
                <button
                    style={{
                        flex: 1,
                        backgroundColor: "#28a745",
                        color: "white",
                        padding: "12px",
                        borderRadius: "8px",
                        border: "none",
                        fontSize: "16px",
                        cursor: "pointer",
                        textAlign: "center",
                        fontWeight: "bold",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() => navigate("/downloads")}
                >
                    📥 My Downloads
                </button>

                <button
                    style={{
                        flex: 1,
                        backgroundColor: "#007bff",
                        color: "white",
                        padding: "12px",
                        borderRadius: "8px",
                        border: "none",
                        fontSize: "16px",
                        cursor: "pointer",
                        textAlign: "center",
                        fontWeight: "bold",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() => { navigate("/ebooks") }}
                >
                    🛒 Go to Store
                </button>
            </div>
        </div>
    );
};

export default EbookCheckout;