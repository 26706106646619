// import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';

// import "./Footer.css";
// import Wave from "../../img/wave.png";

// // ICONS
// import Insta from '@iconscout/react-unicons/icons/uil-instagram';
// import Facebook from '@iconscout/react-unicons/icons/uil-facebook';
// import Github from '@iconscout/react-unicons/icons/uil-github';

// const Footer = () => {
//   const location = useLocation(); // Get current route
//   const navigate = useNavigate(); // Hook to navigate

//   const isfounderPage = location.pathname === '/founder';

//   return (
//     <div className="footer">
//       <img src={Wave} alt="Wave" style={{ width: "100%", zIndex: "-1" }} />
//       <div className="f-content">
//         <div className="f-icons">
//           <Insta color="white" size="3rem" />
//           <Facebook color="white" size="3rem" />
//           <Github color="white" size="3rem" />
//         </div>
//         <span style={{ fontSize: "25px" }}>
//           {isfounderPage ? `sumansaurabh00@gmail.com` : "www.sscvidya.in"}
//         </span>
//         <div>
//           <span
//             style={{ cursor: "pointer", marginRight: "20px" }}
//             onClick={() => navigate('/contact-us')}
//           >
//             Contact Us
//           </span>
//           <span
//             style={{ cursor: "pointer" }}
//             onClick={() => navigate('/terms-conditions')}
//           >
//             Terms & Conditions
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import "./Footer.css";
import Wave from "../../img/wave.png";

// ICONS
import Insta from '@iconscout/react-unicons/icons/uil-instagram';
// import Facebook from '@iconscout/react-unicons/icons/uil-facebook';
import Telegram from '@iconscout/react-unicons/icons/uil-telegram';
import Youtube from '@iconscout/react-unicons/icons/uil-youtube';
import Whatsapp from '@iconscout/react-unicons/icons/uil-whatsapp';

const Footer = () => {
  const location = useLocation(); // Get current route
  const navigate = useNavigate(); // Hook to navigate

  const isfounderPage = location.pathname === '/founder';
  const hideFooterRoutes = ["/ebooks", "/ecart", "/echeckout", "/downloads"];

  // ✅ If current path is in `hideFooterRoutes`, don't render the footer
  if (hideFooterRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <div className="footer">
      <img src={Wave} alt="Wave" style={{ width: "100%", zIndex: "-1" }} />
      <div className="f-content">
        <div className="f-icons">
          <Insta color="white" size="3rem" style={{ cursor: "pointer" }} onClick={() => window.open("https://www.instagram.com/sscvidya.in/", "_blank")} />
          <Youtube color="white" size="3rem" style={{ cursor: "pointer" }} onClick={() => window.open("https://www.youtube.com/@sscvidya", "_blank")} />
          <Telegram color="white" size="3rem" style={{ cursor: "pointer" }} onClick={() => window.open("https://t.me/sscpyqQuestions", "_blank")} />
          <Whatsapp color="white" size="3rem" style={{ cursor: "pointer" }} onClick={() => window.open("https://whatsapp.com/channel/0029Vb7zccZ47XeL2teCl21e", "_blank")} />
        </div>
        <span style={{ fontSize: "25px" }}>
          {isfounderPage ? `sumansaurabh00@gmail.com` : "sscvidya.in"}
          <span
            style={{ cursor: "pointer", marginLeft: "20px" }}
            onClick={() => navigate('/about-us')}
          >
            About Us
          </span>
        </span>
        <div>

          <span
            style={{ cursor: "pointer", marginRight: "20px" }}
            onClick={() => navigate('/contact-us')}
          >
            Contact Us
          </span>
          <span
            style={{ cursor: "pointer", marginRight: "20px" }}
            onClick={() => navigate('/terms-conditions')}
          >
            Terms & Conditions
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate('/cancellation-refund-policy')}
          >
            Refund Policy
          </span>
        </div>

      </div>
    </div>
  );
};

export default Footer;