import React from 'react';
import { Helmet } from 'react-helmet';

const CancellationRefundPolicy = ({ darkMode }) => {
    return (
        <div style={{
            maxWidth: '800px',
            margin: '0 auto',
            padding: '20px',
            color: darkMode ? '#ffffff' : '#333333',
            backgroundColor: darkMode ? '#121212' : '#ffffff',
            lineHeight: '1.6'
        }}>
            <Helmet>
                <title>Cancellation & Refund Policy | SUMAN SAURAV</title>
                <meta name="description" content="Our cancellation and refund policy for all purchases" />
            </Helmet>

            <h1 style={{
                textAlign: 'center',
                marginBottom: '30px',
                color: darkMode ? '#ffffff' : '#2c3e50',
                borderBottom: `2px solid ${darkMode ? '#444' : '#eee'}`,
                paddingBottom: '10px'
            }}>
                Cancellation & Refund Policy
            </h1>

            <p style={{ textAlign: 'right', marginBottom: '30px', fontSize: '0.9em' }}>
                Last updated on 01-04-2025 12:28:35
            </p>

            <div style={{ marginBottom: '40px' }}>
                <p>
                    SUMAN SAURAV believes in helping its customers as far as possible, and has therefore a liberal
                    cancellation policy.
                </p>
            </div>

            <div style={{ marginBottom: '30px' }}>
                <h2 style={{
                    color: darkMode ? '#4CAF50' : '#2c3e50',
                    marginBottom: '15px',
                    fontSize: '1.3em'
                }}>
                    Cancellation Policy
                </h2>
                <ul style={{ paddingLeft: '20px' }}>
                    <li style={{ marginBottom: '10px' }}>
                        Cancellations will be considered only if the request is made immediately after placing the order.
                        However, the cancellation request may not be entertained if the orders have been communicated to the
                        vendors/merchants and they have initiated the process of shipping them.
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                        SUMAN SAURAV does not accept cancellation requests for perishable items like flowers, eatables etc.
                        However, refund/replacement can be made if the customer establishes that the quality of product
                        delivered is not good.
                    </li>
                </ul>
            </div>

            <div style={{ marginBottom: '30px' }}>
                <h2 style={{
                    color: darkMode ? '#4CAF50' : '#2c3e50',
                    marginBottom: '15px',
                    fontSize: '1.3em'
                }}>
                    Refund Policy
                </h2>
                <ul style={{ paddingLeft: '20px' }}>
                    <li style={{ marginBottom: '10px' }}>
                        In case of receipt of damaged or defective items please report the same to our Customer Service team.
                        The request will, however, be entertained once the merchant has checked and determined the same at his
                        own end. This should be reported within 7 days of receipt of the products.
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                        In case you feel that the product received is not as shown on the site or as per your expectations,
                        you must bring it to the notice of our customer service within 7 days of receiving the product.
                        The Customer Service Team after looking into your complaint will take an appropriate decision.
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                        In case of complaints regarding products that come with a warranty from manufacturers,
                        please refer the issue to them.
                    </li>
                    <li>
                        In case of any Refunds approved by the SUMAN SAURAV, it'll take 3-5 days for the refund
                        to be processed to the end customer.
                    </li>
                </ul>
            </div>

            <div style={{ marginTop: '40px', padding: '20px', backgroundColor: darkMode ? '#1e1e1e' : '#f9f9f9', borderRadius: '5px' }}>
                <h3 style={{ color: darkMode ? '#4CAF50' : '#2c3e50', marginBottom: '15px' }}>
                    Contact Our Customer Service
                </h3>
                <p>
                    For any questions or concerns regarding cancellations or refunds, please contact our customer service team at:
                </p>
                <p style={{ marginTop: '10px', fontWeight: 'bold' }}>
                    Email: sscvidya.in@gmail.com<br />
                    Phone: +91-9939720421<br />
                    Hours: Mon-Sat, 10AM to 6PM
                </p>
            </div>
        </div>
    );
};

export default CancellationRefundPolicy;