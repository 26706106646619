import React from "react";

const AboutUs = () => {
    const styles = {
        container: {
            maxWidth: "800px",
            margin: "20px auto",
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            lineHeight: "1.6",
            color: "#333",
            border: "1px solid #ddd",
            borderRadius: "5px",
            backgroundColor: "#f9f9f9",
        },
        heading: {
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "10px",
            color: "#444",
        },
        subHeading: {
            fontSize: "14px",
            fontStyle: "italic",
            color: "#666",
            marginBottom: "15px",
        },
        sectionTitle: {
            fontSize: "18px",
            fontWeight: "bold",
            marginTop: "15px",
            color: "#222",
        },
        paragraph: {
            fontSize: "14px",
            marginBottom: "10px",
            textAlign: "justify",
        },
        list: {
            marginLeft: "20px",
        },
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>About Us</h1>
            <p style={styles.subHeading}>Empowering Aspirants for Government Exams</p>

            <p style={styles.paragraph}>
                Welcome to <strong>sscvidya.in</strong>, your one-stop destination for comprehensive educational resources,
                study materials, and guidance for government exams. Our mission is to empower aspirants like you with
                the tools and knowledge needed to succeed in competitive exams.
            </p>

            <h2 style={styles.sectionTitle}>Who We Are</h2>
            <p style={styles.paragraph}>
                <strong>sscvidya.in</strong> is an educational platform dedicated to providing high-quality study
                materials, quizzes, and previous years' question papers for various government exams. We understand
                the challenges faced by aspirants and strive to make learning accessible, efficient, and effective.
            </p>

            <h2 style={styles.sectionTitle}>What We Offer</h2>
            <ul style={styles.list}>
                <li style={styles.paragraph}>
                    <strong>Study Materials:</strong> Comprehensive notes and resources for SSC, UPSC, Banking, and
                    other government exams.
                </li>
                <li style={styles.paragraph}>
                    <strong>Previous Years' Question Papers:</strong> Access to solved and unsolved question papers
                    to help you understand exam patterns.
                </li>
                <li style={styles.paragraph}>
                    <strong>Quizzes:</strong> Interactive quizzes to test your knowledge and improve your preparation.
                </li>
                <li style={styles.paragraph}>
                    <strong>Exam Updates:</strong> Stay updated with the latest notifications, exam dates, and
                    eligibility criteria.
                </li>
                <li style={styles.paragraph}>
                    <strong>Expert Guidance:</strong> Tips and strategies from experienced educators to help you
                    crack your exams.
                </li>
            </ul>

            <h2 style={styles.sectionTitle}>Our Mission</h2>
            <p style={styles.paragraph}>
                Our mission is to democratize education by providing free and affordable resources to aspirants from
                all walks of life. We believe that everyone deserves a chance to achieve their dreams, and we are here
                to support you every step of the way.
            </p>

            <h2 style={styles.sectionTitle}>Why Choose Us?</h2>
            <ul style={styles.list}>
                <li style={styles.paragraph}>
                    <strong>Quality Content:</strong> Our materials are curated by experts and updated regularly.
                </li>
                <li style={styles.paragraph}>
                    <strong>User-Friendly Platform:</strong> Easy navigation and a seamless learning experience.
                </li>
                <li style={styles.paragraph}>
                    <strong>Free Resources:</strong> Access a wealth of study materials at no cost.
                </li>
                <li style={styles.paragraph}>
                    <strong>Community Support:</strong> Join a community of like-minded aspirants and share your
                    journey.
                </li>
            </ul>

            <h2 style={styles.sectionTitle}>Contact Us</h2>
            <p style={styles.paragraph}>
                Have questions or feedback? We'd love to hear from you! Reach out to us at{" "}
                <strong>sscvidya.in@gmail.com</strong> or visit our website <strong>www.sscvidya.in</strong> for
                more information.
            </p>
        </div>
    );
};

export default AboutUs;