import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
// import quizData from "../data/quizzes.json"; // Import your JSON data
import allData from "../data/allData";// Import your JSON data
import { themeContext } from '../Context';
import { auth } from '../firebase/firebaseConfig';
import QuizCategorySEO from '../utilities/SEO/QuizCategorySEO';
import SharePopup from '../utilities/SharePopup';
import SharePopupWithVideo from '../utilities/SharePopupWithVideo';
import { saveQuizAttempt } from '../utilities/SaveQuizAttempt';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase/firebaseConfig';
function DummyPage() {
    const { quizType, quizSet } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedOptions, setSelectedOptions] = useState({});
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);
    const [unattempted, setUnattempted] = useState(0);
    const [user, setUser] = useState(null);

    // State to Manage Popup Visibility
    const [showPopup, setShowPopup] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [activeVideoQuestion, setActiveVideoQuestion] = useState(null);

    // State to Manage Popup Visibility
    const [showVideoPopup, setShowVideoPopup] = useState(false);

    // Handler to Open Popup
    const handleShareClick = (question) => {
        setActiveQuestion(question);
        setShowPopup(true);
        // console.log("question", question)
    };

    // Handler to Open Popup
    const handleVideoShareClick = (question) => {
        setActiveVideoQuestion(question);
        setShowVideoPopup(true);
        // console.log("question", question)
    };

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    // Effect to track the authentication state
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(currentUser => {
            setUser(currentUser);
        });
        return () => unsubscribe(); // Unsubscribe on component unmount
    }, []);



    // Determine if we should display the header based on the current path
    const showHeading = location.pathname === `/quizCategory` || location.pathname.endsWith('/quizcategory/');
    // const showSubHeading = location.pathname.includes('/quizCategory/');
    const showSubHeading = location.pathname.startsWith('/quizCategory/') && location.pathname !== '/quizCategory/';
    // const showHeading = !showSubHeading && (location.pathname === '/quizCategory' || location.pathname === '/quizCategory/');



    // Find the quizzes for the selected quiz type and set
    // const selectedCategory = quizData.categories.find(category => category.category === quizType);
    // console.log("selectedCategory", allData);
    // console.log("selectedCategory2", quizData.categories);

    const selectedCategory = allData.find(category => category.category === quizType);
    const selectedSet = selectedCategory && quizSet !== undefined ? selectedCategory.sets[parseInt(quizSet)] : null;
    // console.log("selectedCategory2", selectedCategory);

    // Redirect if quiz set requires authentication and the user is not logged in
    useEffect(() => {
        if (selectedSet && selectedSet.authRequired && !user) {
            navigate('/login'); // Redirect to login page
        }
    }, [selectedSet, user, navigate]);


    // Handle option selection using question ID
    // const handleOptionClick = (questionId, option) => {
    //     setSelectedOptions({
    //         ...selectedOptions,
    //         [questionId]: option,
    //     });
    // };
    const handleOptionClick = (questionId, option) => {
        if (selectedOptions[questionId]) return; // Prevent changing the selection

        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [questionId]: option,
        }));
    };


    const shuffledQuestions = useMemo(() => {
        return selectedSet
            ? selectedSet.quizzes.map(question => ({
                ...question,
                options: [...question.options].sort(() => Math.random() - 0.5),
            }))
            : [];
    }, [selectedSet]);

    // useEffect(() => {
    //     console.log("shuffledQuestions", shuffledQuestions);
    // }, [shuffledQuestions]); // This logs only once on mount

    // Calculate the user's score
    // const calculateScore = () => {
    //     let correctAnswers = 0;
    //     let unattemptedCount = 0;

    //     const questions = selectedSet ? selectedSet.quizzes : [];
    //     questions.forEach((question) => {
    //         const userAnswer = selectedOptions[question.id];
    //         if (userAnswer === question.correctAnswer) {
    //             correctAnswers += 1;
    //         } else if (!userAnswer) {
    //             unattemptedCount += 1;
    //         }
    //     });

    //     setScore(correctAnswers);
    //     setUnattempted(unattemptedCount);
    //     setShowScore(true);
    // };
    const calculateScore = async () => {
        let correctAnswers = 0;
        let unattemptedCount = 0;

        const questions = selectedSet ? selectedSet.quizzes : [];
        questions.forEach((question) => {
            const userAnswer = selectedOptions[question.id];
            if (userAnswer === question.correctAnswer) {
                correctAnswers += 1;
            } else if (!userAnswer) {
                unattemptedCount += 1;
            }
        });

        const attemptData = {
            score: correctAnswers,
            unattempted: unattemptedCount,
            totalQuestions: questions.length,
            timestamp: new Date().toISOString(),
        };

        setScore(correctAnswers);
        setUnattempted(unattemptedCount);
        setShowScore(true);

        // Save to Firestore
        if (user) {
            await saveQuizAttempt(user.uid, quizType, quizSet, attemptData);
            alert("Quiz attempt saved successfully!");
        } else {
            alert("Please log in to save your quiz progress.");
        }

    };

    const [updatedCategory, setUpdatedCategory] = useState(selectedCategory);

    useEffect(() => {
        const fetchUserQuizAttempts = async () => {
            if (user) {
                const userDocRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const quizAttempts = userDoc.data().quizAttempts || {};

                    // Create a new copy of selectedCategory
                    const updatedSets = selectedCategory?.sets.map((set, index) => {
                        if (quizAttempts[quizType]?.[index]) {
                            return {
                                ...set,
                                attempted: true,
                                lastScore: quizAttempts[quizType][index].score,
                            };
                        }
                        return set;
                    });

                    // Update the state with the new copy
                    setUpdatedCategory({
                        ...selectedCategory,
                        sets: updatedSets,
                    });
                }
            }
        };

        fetchUserQuizAttempts();
    }, [user, quizType, selectedCategory, location.pathname]);


    // Style for answers
    const getOptionStyle = (questionId, option) => {
        if (!selectedSet) return {};
        const question = selectedSet.quizzes.find(q => q.id === questionId);
        const correctAnswer = question.correctAnswer;
        const selectedOption = selectedOptions[questionId];

        if (option === correctAnswer && selectedOption === option) {
            return { backgroundColor: 'lightgreen' };
        }
        if (option === selectedOption && option !== correctAnswer) {
            return { backgroundColor: 'lightcoral' };
        }
        if (selectedOption && correctAnswer === option) {
            return { backgroundColor: 'lightgreen' };
        }
        return { backgroundColor: darkMode ? "rgb(128 128 128 / 42%)" : '#f0f0f0' };
    };

    // Handle Refresh
    const handleRefresh = () => {
        navigate('/quizCategory');
        setSelectedOptions({});
        setShowScore(false);
        setUnattempted(0);
    };
    //HANDLE SEARCH IN QUIZ PAGE
    const recommendedSearchesMap = {
        "current-affairs-2024": ["September"],
        "static-gk-pyq": ["Census", "MTS 2024", "Parmar", "Arts and Culture", "Books", "Sports", "Gharana", "Songs", "Dance", "Painting", "Fairs", "Firsts in India", "Festivals"],
        "history-pyq-sscvidya": ["CGL 2024", "MTS 2024", "Mordern"],
        "polity-pyq-sscvidya": ["MTS 2024",],
        "geography-pyq-ssc-questions-sscvidya": ["CGL 2024", "MTS 2024", "Origin of Universe", "Solar System", "Earth", "Tectonic Plates", "Indian Mountains", "Rocks", "Atmosphere", "Seasons in India", "Borders of India", "Physiographic Divisions", "Himalayan", "Ports in India", "Rivers of India", "Waterfalls", "Waterways"],
        // Add more categories as needed 
    };
    const [searchQuery, setSearchQuery] = useState('');
    const [showRecommendations, setShowRecommendations] = useState(false);

    // Extract quiz category from URL
    const categoryFromUrl = location.pathname.split("/")[2]; // Assuming URL format: /quizCategory/:category
    // Get recommended searches based on the extracted category
    const recommendedSearches = recommendedSearchesMap[categoryFromUrl] || [];
    // Filter quiz sets based on the search query
    // const filteredSets = selectedCategory?.sets.filter((set) =>
    //     set.title.toLowerCase().includes(searchQuery.toLowerCase())
    // );
    const filteredSets = selectedCategory?.sets
        .map((set, index) => ({ ...set, originalIndex: index })) // Keep original index
        .filter((set) => set.title.toLowerCase().includes(searchQuery.toLowerCase()));

    // console.log("filteredSets:", filteredSets);
    useEffect(() => {
        setSearchQuery(""); // Reset search query when category changes
    }, [categoryFromUrl]); // Runs when category changes


    return (
        <div style={{ padding: '20px', minHeight: "100vh", overflowY: 'auto' }}>
            {/* Helmet for setting the head elements */}
            {selectedCategory && (
                <QuizCategorySEO
                    category={selectedCategory?.category}
                    imageURL={selectedCategory?.imageURL}
                    quizzes={selectedCategory?.sets.flatMap((set) => set.quizzes)}
                />
            )}

            {/* Conditional Rendering of the Heading */}
            {showHeading && (
                <div className="t-heading" style={{ marginBottom: "20px" }}>
                    <span>SSC CGL Exam </span>
                    <span>Practice MCQ Quiz </span>
                    <span>Topic-Wise Questions for </span>
                    <span>CGL, MTS, CHSL</span>
                </div>
            )}
            {/* <h1>{quizType ? `${quizType.charAt(0).toUpperCase() + quizType.slice(1)} Quiz` : 'Select a Quiz:'}</h1> */}
            {showSubHeading && (
                <div className="t-heading" style={{ marginBottom: "20px" }}>
                    <span>Practice MCQ Quiz </span>
                    <span>{quizType ? `${quizType.replace(/-/g, ' ').toUpperCase()}` : 'Select a Quiz:'}</span>
                    <span> Topic-Wise Questions.</span>
                </div>
            )}
            {/* Step 1: Quiz Type Selection */}
            {!quizType && (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px', flexWrap: "wrap" }}>

                    {allData.map((quiz) => (
                        <div
                            key={quiz.category}
                            onClick={() => navigate(`/quizCategory/${quiz.category}`)}
                            style={{
                                cursor: 'pointer',
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                width: '200px',
                                textAlign: 'center',
                                padding: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <img
                                src={quiz.imageURL}
                                alt={quiz.category}
                                style={{ width: '100%', borderRadius: '10px' }}
                            />
                            <p style={{ fontWeight: 'bold', marginTop: '10px' }}>{quiz.category.replace(/-/g, ' ').toUpperCase()}</p>
                        </div>
                    ))}
                </div>
            )}

            {/* Step 2: Quiz Set Selection */}
            {/* Search Bar */}
            {quizType && quizSet === undefined && selectedCategory && (
                <div style={styles.searchContainer}>
                    <input
                        type="text"
                        placeholder="Search quiz sets..."
                        value={searchQuery}
                        // onChange={(e) => setSearchQuery(e.target.value)}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            if (e.target.value.trim() === '') {
                                setShowRecommendations(true);
                            } else {
                                setShowRecommendations(false);
                            }
                        }}
                        onFocus={() => setShowRecommendations(true)}
                        onBlur={() => setTimeout(() => setShowRecommendations(false), 200)}
                        style={styles.searchInput}
                    />
                    {/* Recommended searches */}
                    {showRecommendations && recommendedSearches.length > 0 && (
                        <div style={styles.recommendationsContainer}>
                            {recommendedSearches.map((term, index) => (
                                <div
                                    key={index}
                                    onClick={() => setSearchQuery(term)}
                                    style={styles.recommendationItem}
                                >
                                    {term}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            {/* Quiz List Display */}
            {quizType && quizSet === undefined && selectedCategory && (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px', flexWrap: "wrap" }}>
                    <QuizCategorySEO
                        category={selectedCategory?.category}
                        imageURL={selectedCategory?.imageURL}
                        quizzes={selectedCategory?.sets.flatMap((set) => set.quizzes)}
                    />
                    {
                        filteredSets.length === 0 ? (
                            <p style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                color: "#555",
                                textAlign: "center",
                                width: "100%",
                                marginTop: "20px"
                            }}>
                                ❌ No results found for "{searchQuery}"
                            </p>
                        ) : (filteredSets.map((set, index) => (

                            <div
                                key={set.set}
                                // onClick={() => navigate(`/quizCategory/${quizType}/${index}`)}

                                onClick={() => {
                                    if (set.authRequired && !user) {
                                        navigate('/login');
                                    } else {
                                        navigate(`/quizCategory/${quizType}/${set.originalIndex}`);
                                    }
                                }}
                                style={{
                                    cursor: 'pointer',
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    width: '200px',
                                    textAlign: 'center',
                                    padding: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                <p style={{ fontWeight: 'bold', marginTop: '10px' }}>
                                    {set.title} (Questions: {set.quizzes.length})
                                </p>
                                {/* Display login message if auth is required and user is not logged in */}
                                {set.authRequired && !user && (
                                    <p style={{ color: 'red', fontSize: '0.9em', marginTop: '5px' }}>
                                        Please login to access.
                                    </p>
                                )}
                                <p>
                                    {
                                        user &&
                                        updatedCategory?.sets &&
                                        updatedCategory?.sets[set.originalIndex] &&
                                        updatedCategory?.sets[set.originalIndex].attempted && (
                                            <p>
                                                <span style={{ color: "green", fontSize: "14px" }}>
                                                    Completed! Score: <span style={{ color: "green", fontSize: "15px", fontWeight: "bold" }}>{updatedCategory?.sets[set.originalIndex]?.lastScore}</span>
                                                </span>
                                            </p>
                                        )
                                    }
                                </p>
                                {/* Completed Icon shows if the set has been attempted of not. */}
                            </div>
                        ))
                        )}
                </div>
            )}

            {/* Step 3: Display Quiz Questions */}
            {selectedSet && (
                <div>
                    {shuffledQuestions.map((question, index) => (
                        <div key={question.id} id={`question-${question.id}`} style={{ marginBottom: '20px' }}>
                            <h3>{index + 1}. {question.question} <span style={{ fontWeight: "bold" }}>{question?.exam}</span></h3>
                            <ul>
                                {question.options
                                    .map((option) => (
                                        <li
                                            key={option}
                                            onClick={() => handleOptionClick(question.id, option)}
                                            style={{
                                                padding: '10px',
                                                margin: '5px 0',
                                                // cursor: 'pointer', ...getOptionStyle(question.id, option)
                                                cursor: selectedOptions[question.id] ? 'not-allowed' : 'pointer',
                                                ...getOptionStyle(question.id, option)
                                            }}
                                        >
                                            {option}
                                        </li>
                                    ))}
                            </ul>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <button
                                    onClick={() => handleShareClick(question)}
                                    className="button"
                                    style={{ fontSize: "small" }}

                                >
                                    Share or Save
                                </button>
                                <button
                                    onClick={() => handleVideoShareClick(question)}
                                    className="button"
                                    style={{ marginLeft: "5px", fontSize: "small" }}
                                >
                                    Save as Video
                                </button>
                            </div>

                        </div>
                    ))}
                    <button className="button" onClick={calculateScore}>Submit</button>
                    {showScore && (
                        <div>
                            <h2>Your Score: {score} out of {selectedSet.quizzes.length}</h2>
                            <h2>Unattempted: {unattempted}</h2>
                            <button className="button" onClick={handleRefresh}>Refresh Quiz</button>
                        </div>
                    )}
                    {/* Render the SharePopup */}
                    {showPopup && activeQuestion && (
                        <SharePopup
                            question={activeQuestion}
                            onClose={() => setShowPopup(false)}
                        />
                    )}
                    {/* Render the videoSharePopup */}
                    {showVideoPopup && activeVideoQuestion && (
                        <SharePopupWithVideo
                            question={activeVideoQuestion}
                            onClose={() => setShowVideoPopup(false)}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

const styles = {
    searchContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        marginBottom: "20px",
        width: "100%",
    },
    searchInput: {
        padding: "12px",
        width: "80%",
        borderRadius: "8px",
        border: "2px solid #007bff",
        fontSize: "16px",
        outline: "none",
        transition: "0.3s",
        boxShadow: "0 2px 5px rgba(0, 123, 255, 0.2)"
    },
    recommendationsContainer: {
        position: "absolute",
        width: "80%",
        background: "#fff",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        marginTop: "50px",
        zIndex: 10,
        overflowY: "auto",  // Enables scrolling
        maxHeight: "200px",  // Restricts max height        
    },
    recommendationItem: {
        padding: "12px",
        cursor: "pointer",
        fontSize: "14px",
        borderBottom: "1px solid #f0f0f0",
        transition: "0.3s",
        textAlign: "left",
        color: "#333",
        backgroundColor: "#fff"
    },
    recommendationItemHover: {
        backgroundColor: "#007bff",
        color: "#fff"
    }
};
export default DummyPage;
