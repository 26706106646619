import React from "react";
import Slider from "react-slick"; // Library for carousel/slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const SliderSection = ({ sliderData }) => {
    const navigate = useNavigate();

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        <div style={{ marginBottom: '30px' }}>
            <Slider {...sliderSettings}>
                {sliderData.map((slide) => (
                    <div key={slide.id} style={{ position: 'relative' }}>
                        <div>
                            <img
                                onClick={() => navigate(slide.link)}
                                src={slide.image}
                                alt={slide.title}
                                style={{
                                    width: '100%', borderRadius: '10px', maxHeight: '300px', objectFit: 'cover', cursor: 'pointer',
                                }}
                            />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                marginTop: "10px",
                                bottom: '5px',
                                width: "80%",
                                // left: '20px',
                                color: '#fff',
                                background: 'rgba(0, 0, 0, 0.5)',
                                padding: '5px 0px 5px 0px',
                                // borderRadius: '2%',
                                // marginLeft: "-20px"
                            }}
                        >
                            <h3 style={{ marginLeft: "4vw", fontWeight: "bold" }}>{slide.title}</h3>
                            {/* <button
                                    onClick={() => navigate(slide.link)}
                                    style={{
                                        background: '#007bff',
                                        border: 'none',
                                        color: '#fff',
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                    }}
                                >
                                    Explore
                                </button> */}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SliderSection;
