// //src/components/store.js
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { auth } from "../../firebase/firebaseConfig";
// import PreviewCard from "../previewCard/PreviewCard";
// const books = [
//     { id: 1, name: "React Guide", price: 1 },
//     { id: 2, name: "Firebase Handbook", price: 1 },
//     { id: 3, name: "JavaScript Notes", price: 1 },
// ];

// const Store = ({ cart, setCart }) => {
//     const [user, setUser] = useState(null);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const unsubscribe = auth.onAuthStateChanged((currentUser) => {
//             setUser(currentUser);
//         });
//         return () => unsubscribe();
//     }, []);

//     const addToCart = (book) => {
//         if (!user) {
//             alert("Please login to add items to cart!");
//             return;
//         }
//         if (cart.some((item) => item.id === book.id)) {
//             alert("This book is already in your cart!");
//             return;
//         }
//         setCart([...cart, book]);
//     };

//     return (
//         <div style={{ padding: "20px" }}>
//             <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px" }}>
//                 Book Store
//             </h2>
//             <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "16px" }}>
//                 {books.map((book) => (
//                     <div
//                         key={book.id}
//                         style={{
//                             padding: "16px",
//                             border: "1px solid #ddd",
//                             borderRadius: "8px",
//                             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//                         }}
//                     >
//                         <h3 style={{ fontWeight: "600" }}>{book.name}</h3>
//                         <p>₹{book.price}</p>
//                         <button
//                             className="button n-button"
//                             onClick={() => addToCart(book)}
//                             disabled={!user}
//                         >
//                             {user ? "Add to Cart" : "Login to Add"}
//                         </button>
//                     </div>
//                 ))}
//             </div>
//             <button
//                 className="button n-button"
//                 style={{
//                     marginTop: "20px",
//                     cursor: "pointer",
//                 }}
//                 onClick={() => navigate("/cart")}
//             >
//                 Go to Cart ({cart.length})
//             </button>
//         </div>
//     );
// };

// export default Store;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebaseConfig";
import PreviewCard from "../previewCard/PreviewCard";
const books = [
    {
        id: 1,
        name: "React Guide",
        price: 1,
        description: "Master React with this beginner-friendly guide.",
        image: "https://firebase.google.com/images/social.png",
    },
    {
        id: 2,
        name: "Firebase Handbook",
        price: 1,
        description: "Learn Firebase for seamless app development.",
        image: "https://firebase.google.com/images/social.png",
    },
    {
        id: 3,
        name: "JavaScript Notes",
        price: 1,
        description: "Essential JavaScript concepts in one place.",
        image: "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png",
    },
];

const Store = ({ cart, setCart, darkMode }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    const addToCart = (book) => {
        if (!user) {
            alert("Please login to add items to cart!");
            return;
        }
        if (cart.some((item) => item.id === book.id)) {
            alert("This book is already in your cart!");
            return;
        }
        setCart([...cart, book]);
        alert(`${book.name} added to cart!`);
    };

    return (
        <div style={{ padding: "20px" }}>
            <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px" }}>
                Book Store
            </h2>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
                {[...books].reverse().map((book) => (
                    <PreviewCard
                        key={book.id}
                        card={{
                            id: book.id,
                            title: book.name,
                            description: book.description,
                            image: book.image,
                            link: "#", // No need for a link in this case
                        }}
                        darkMode={darkMode}
                        price={book.price}
                        size="small" // Options: "small", "default", "large"
                        addToCart={() => addToCart(book)} // Pass function
                    />
                ))}
            </div>

            <button
                className="button n-button"

                style={{
                    marginTop: "20px",
                }}
                // onClick={() => navigate("/cart")}
                onClick={() => {
                    if (cart.length === 0) {
                        alert("Your cart is empty! Add items before viewing your cart.");
                    } else {
                        navigate("/cart");
                    }
                }}
            >
                Go to Cart ({cart.length})
            </button>
        </div>
    );
};

export default Store;
