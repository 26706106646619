import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebaseConfig";
import PreviewCard from "../previewCard/PreviewCard";
import axios from "axios";

const ebooks = [
    {
        id: 1,
        name: "SSC CGL General Awareness PYQ 2024",
        price: 5,
        description: "Complete SSC CGL General Awareness 2024 TopicWise Questions.",
        image: "https://i.imgur.com/8qS0n1C_d.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1Ku_j3cFvnVaf5lFDGU3bXsvPl4S00bY_/view?usp=sharing"
    },
    {
        id: 2,
        name: "SSC CHSL General Awareness PYQ 2024.",
        price: 5,
        description: "Complete SSC CHSL General Awareness 2024 TopicWise Questions.",
        image: "https://i.imgur.com/jCA9KUo_d.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1--KQovmQ8WXq87_RbNlKspQ7fFOyIoNh/view?usp=sharing"
    },
    {
        id: 3,
        name: "SSC GD General Awareness PYQ 2024.",
        price: 5,
        description: "Complete SSC GD General Awareness 2024 TopicWise Questions.",
        image: "https://i.imgur.com/nXRZuYM_d.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1-8U0mSiqU814blPXcHGTfWdBb7u9Levf/view?usp=sharing"
    },
    {
        id: 4,
        name: "SSC CPO General Awareness PYQ 2024.",
        price: 5,
        description: "Complete SSC CPO General Awareness 2024 TopicWise Questions.",
        image: "https://i.imgur.com/9H4CGnt.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1-1ec3N8bfvEQU1jUMX0FNAMbdRM12xPL/view?usp=sharing"
    },
    {
        id: 33,
        name: "SSC MTS General Awareness PYQ 2024.",
        price: 5,
        description: "Complete SSC MTS General Awareness 2024 TopicWise Questions.",
        image: "https://i.imgur.com/efWkgIo_d.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1Y_V04I4BMoZ6nNVOjQIIJdlIxr7HIOZA/view?usp=sharing"
    },
    // {
    //     id: 5,
    //     name: "Sports Part 1 : Parmar Classes Batch 3.",
    //     price: 2.5,
    //     description: "Sports Part 1 : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/QOR39dn_d.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1KjE3-PPEFWMskshniSOp4ijqYPriF-Lh/view?usp=sharing"
    // },
    // {
    //     id: 6,
    //     name: "Sports Part 2 : Parmar Classes Batch 3.",
    //     price: 2.5,
    //     description: "Sports Part 2 : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/JUp1OO3_d.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1RFO6q5N3b0l7_BnJydMd90zyr6ejQN8_/view?usp=sharing"
    // },
    // {
    //     id: 7,
    //     name: "Research Institutes : Parmar Classes Batch 3.",
    //     price: 5,
    //     description: "Research Institutes of India : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/0GHrRcx_d.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1FGjTi_ddny1ryJeQ8KeqQh47kFDiXxYw/view?usp=sharing"
    // },
    // {
    //     id: 8,
    //     name: "International Organisations : Parmar Classes Batch 3.",
    //     price: 5,
    //     description: "International Organisations Parmar Classes Notes : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/qF7GGUN_d.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1I4LjhE439Y32t4Zr4-O88zFQqZUHUw3O/view?usp=sharing"
    // },
    // {
    //     id: 9,
    //     name: "Census of India : Parmar Classes Batch 3.",
    //     price: 5,
    //     description: "Census of India Parmar Classes Notes : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/LNfkahi_d.webp?maxwidth=760&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1FPJJhnIZXyggFdaDnqh6zL4uEKDQDdft/view?usp=sharing"
    // },
    // {
    //     id: 10,
    //     name: "Musical Instruments : Parmar Classes Batch 3.",
    //     price: 5,
    //     description: "Musical Instruments Parmar Classes Notes : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/r1IFJA8_d.webp?maxwidth=760&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1FfE_WrjSpigRqXeC-yDMJTxpg6-OTPyM/view?usp=sharing"
    // },
    // {
    //     id: 11,
    //     name: "Gharanas : Parmar Classes Batch 3.",
    //     price: 5,
    //     description: "Gharanas Parmar Classes Notes : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/kTdK0CZ.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1FaDGupPe_7Tfawp6xOAKyUjHMmT8AKCa/view?usp=sharing"
    // },
    // {
    //     id: 12,
    //     name: "CHEMISTRY BATCH NOTES & ALL SETS QUESTIONS PARMAR SIR  BATCH 3.",
    //     price: 5,
    //     description: "Chemistry Parmar Classes Notes : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/2zvzVIy.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1Eysn--yh66kfoB85SAap5Dis4bNl7wvl/view?usp=sharing"
    // },

    // {
    //     id: 15,
    //     name: "BOOKS AND AUTHORS PART 1 HINDI Batch 3.",
    //     price: 5,
    //     description: "BOOKS AND AUTHORS PART 1 HINDI : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/dCO9HVm.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/14W9iMD6Di9wKLnmWlyAhqMUCc5GdMlmJ/view?usp=sharing"
    // },
    // {
    //     id: 16,
    //     name: "BOOKS AND AUTHORS PART 1 ENGLISH Batch 3.",
    //     price: 5,
    //     description: "BOOKS AND AUTHORS PART 1 ENGLISH : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/hpmGfI9.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1NnQvcpS8rfkKffdoRTbIKsz0IyRTgJMd/view?usp=sharing"
    // },
    // {
    //     id: 17,
    //     name: "BOOKS AND AUTHORS PART 2 ENGLISH Batch 3.",
    //     price: 5,
    //     description: "BOOKS AND AUTHORS PART 2 ENGLISH : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/Mwe6lsC.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1uLlOeJkzyVW_IB_YW8EMBDd3GGHLa9r_/view?usp=sharing"
    // },
    // {
    //     id: 18,
    //     name: "CENSUS BY PARMAR CLASSES HINDI Batch 3.",
    //     price: 5,
    //     description: "CENSUS BY PARMAR CLASSES HINDI : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/AkpYXge.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/15GI2LNKf6QoVqGAdBI9iJjeqmpzJ_oNI/view?usp=sharing"
    // },
    // {
    //     id: 19,
    //     name: "CENSUS BY PARMAR CLASSES ENGLISH Batch 3.",
    //     price: 5,
    //     description: "CENSUS BY PARMAR CLASSES ENGLISH : Parmar Classes Batch 3.",
    //     image: "https://i.imgur.com/YLxtMFy.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1iC5FRQaPQf0GfWEoKK9ujH21J_AeO4Y_/view?usp=sharing"
    // },
    // {
    //     id: 20,
    //     name: "Classical Dance BY PARMAR CLASSES ENGLISH Batch 3.",
    //     price: 5,
    //     description: "Classical Dance of India BY PARMAR CLASSES  Batch 3.",
    //     image: "https://i.imgur.com/gyuaRq1.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1D8opzz8D8rFQHrmRDKBmIYTzRB9oCpwB/view?usp=sharing"
    // },
    {
        id: 21,
        name: "COMPLETE STATIC GK ENGLISH BY PARMAR SIR 2025.",
        price: 20,
        description: "COMPLETE STATIC GK ENGLISH BY PARMAR CLASSES 2025.",
        image: "https://i.imgur.com/0Q9IRzS.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1H6nL3Lu8J4sms2ps16K9S7I91jMysb2-/view?usp=sharing"
    },
    {
        id: 22,
        name: "COMPLETE STATIC GK HINDI BY PARMAR SIR 2025.",
        price: 20,
        description: "COMPLETE STATIC GK HINDI BY PARMAR CLASSES 2025..",
        image: "https://i.imgur.com/uUjK6HI.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1Njbh7Tw7Hq4l0-FsrGWBYWEr3HIfZAW6/view?usp=sharing"
    },
    // {
    //     id: 23,
    //     name: "GHARANA AND MUSIC HINDI BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "GHARANA AND MUSIC HINDI BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/ZlykAix.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1YZ6PpgFwDDt9q2YsssS7AIlynTeHHoz5/view?usp=sharing"
    // },
    // {
    //     id: 24,
    //     name: "GHARANA AND MUSIC ENGLISH BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "GHARANA AND MUSIC ENGLISH BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/8Rv8x9N.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1jDU60bjxGlg6xXcWczWEOJQLm8BOdWwN/view?usp=sharing"
    // },
    // {
    //     id: 25,
    //     name: "IMPORTANT DAYS HINDI BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "FOLK DANCES PART 1 ENGLISH BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/5PKWmDF.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/105-v1yKGlg9gofKSmLX9WRjzhN5kbryr/view?usp=sharing"
    // },
    // {
    //     id: 26,
    //     name: "IMPORTANT DAYS ENGLISH BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "IMPORTANT DAYS ENGLISH BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/TGBCZRt.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1nI28vFyGuTJ2F-1hQkyQEVZV4OkVJn5H/view?usp=sharing"
    // },
    // {
    //     id: 27,
    //     name: "INTERNATIONAL ORGANISATIONS ENGLISH BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "INTERNATIONAL ORGANISATIONS ENGLISH BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/I6dFRBT.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1WEDHOIDgT1_L0jQ6Uw0XdZDnFCzdBkv8/view?usp=sharing"
    // },
    // {
    //     id: 28,
    //     name: "MUSICAL INSTRUMENTS HINDI BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "MUSICAL INSTRUMENTS HINDI BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/ocYAoVV.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1dPDagI-NbRHv-iwHdoC-oswQTtxFBsS5/view?usp=sharing"
    // },
    // {
    //     id: 29,
    //     name: "MUSICAL INSTRUMENTS ENGLISH BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "MUSICAL INSTRUMENTS ENGLISH BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/3H2oIZ6.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1XAjz69CbsPPfy9HhCXA7mPPCcb2mopHd/view?usp=sharing"
    // },
    // {
    //     id: 30,
    //     name: "SPORTS LECTURE 1 HINDI BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "SPORTS LECTURE 1 HINDI BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/NsyeanU.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/10poLuAXY3o9WAh__l2IzgDW0RS3IUIZX/view?usp=sharing"
    // },
    // {
    //     id: 31,
    //     name: "SPORTS PART 1 ENGLISH BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "SPORTS PART 1 ENGLISH BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/POpMhbH.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1Fi3BuIC21zmlyFhy-DdmkxRlZa2OcEWA/view?usp=sharing"
    // },
    // {
    //     id: 32,
    //     name: "SPORTS PART 2 ENGLISH BY PARMAR CLASSES Batch 3.",
    //     price: 5,
    //     description: "SPORTS PART 2 ENGLISH BY PARMAR CLASSES Batch 3.",
    //     image: "https://i.imgur.com/8UGddPE.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1kMqIIbNZ41YrQDuyNsuBZh36mu_Dd4X8/view?usp=sharing"
    // },
    // //34 se start
    // {
    //     id: 34,
    //     name: "COMPLETE ANCIENT HISTORY ENGLISH BY PARMAR CLASSES.",
    //     price: 10,
    //     description: "Complete Ancient History ENGLISH BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/MdlCuBa.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1PuuKCV07ekLLaDQ__lscvvgBJFMddA_Y/view?usp=sharing"
    // },
    // {
    //     id: 35,
    //     name: "COMPLETE MEDIEVAL HISTORY ENGLISH BY PARMAR CLASSES.",
    //     price: 10,
    //     description: "Complete MEDIEVAL HISTORY ENGLISH BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/Gnk4TB1.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1Pt4ypGWEwlJsrN-nzmn9_GrDeOItMIOx/view?usp=sharing"
    // },
    // {
    //     id: 36,
    //     name: "COMPLETE MODERN HISTORY ENGLISH BY PARMAR CLASSES.",
    //     price: 10,
    //     description: "Complete MODERN HISTORY ENGLISH BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/bbB22Dd.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1PtW4tMJyRiU2_rvP6DR5jZZOOUA2hf8b/view?usp=sharing"
    // },
    // {
    //     id: 37,
    //     name: "COMPLETE PHYSICS ENGLISH BY PARMAR CLASSES.",
    //     price: 10,
    //     description: "Complete PHYSICS ENGLISH BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/CWKYjXN.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1HltLNtN0as10FtnNDo0XgX9T6361Mz6y/view?usp=sharing"
    // },
    // {
    //     id: 38,
    //     name: "COMPLETE PHYSICS HINDI BY PARMAR CLASSES.",
    //     price: 10,
    //     description: "Complete PHYSICS HINDI BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/0qki8ut.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1Hif2RUflZOhf6KEOMLFcQQvxX436TEFR/view?usp=sharing"
    // },
    // {
    //     id: 39,
    //     name: "COMPLETE BIOLOGY LECTURES ENGLISH BY PARMAR CLASSES.",
    //     price: 10,
    //     description: "Complete BIOLOGY LECTURES ENGLISH BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/HiY6Z5B.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1I5fwcJJjezn6IeLVfM_UeiTckYVqxW4e/view?usp=sharing"
    // },
    // {
    //     id: 40,
    //     name: "COMPLETE BIOLOGY LECTURES HINDI BY PARMAR CLASSES.",
    //     price: 10,
    //     description: "Complete BIOLOGY LECTURES HINDI BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/vHVWdsM.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1IDoLkUnSPz_wTSagyDjw40l7-1DifoSY/view?usp=sharing"
    // },
    // {
    //     id: 41,
    //     name: "COMPLETE CHEMISTRY LECTURES ENGLISH BY PARMAR CLASSES.",
    //     price: 10,
    //     description: "Complete CHEMISTRY LECTURES ENGLISH BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/yLkgjpu.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1IJgUslV8AZDLU3xsSltaaWf_gW32IU85/view?usp=sharing"
    // },
    // {
    //     id: 42,
    //     name: "COMPLETE CHEMISTRY LECTURES HINDI BY PARMAR CLASSES.",
    //     price: 10,
    //     description: "Complete CHEMISTRY LECTURES HINDI BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/6Goclwh.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1IJl_YT0YUCQy0EnIZdKdpRi250UmFpH9/view?usp=sharing"
    // },
    // {
    //     id: 43,
    //     name: "INTERNATIONAL ORGANISATIONS HINDI BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "INTERNATIONAL ORGANISATIONS HINDI LECTURE BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/77NMVEp.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1eMUwr4p5BP8XSatIdRWgfwI0XGPp7Kzb/view?usp=sharing"
    // },
    // {
    //     id: 44,
    //     name: "NATIONAL ORGANIZATIONS ENGLISH BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "NATIONAL ORGANIZATIONS ENGLISH LECTURE PDF BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/urbEzRq.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1KuLyFNk8VE2LToRzm9QcHAEK6WEKDbqN/view?usp=sharing"
    // },
    // {
    //     id: 45,
    //     name: "NATIONAL ORGANIZATIONS HINDI BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "NATIONAL ORGANIZATIONS HINDI LECTURE PDF BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/qTrmFXB.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1cCMUpDKyZqA6GK8yNTEhB_ioJ3FJb8e2/view?usp=sharing"
    // },
    {
        id: 46,
        name: "COMPLETE ANCIENT HISTORY ENGLISH BATCH 3 PARMAR SIR 2025.",
        price: 20,
        description: "COMPLETE ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
        image: "https://i.imgur.com/bsZjyvK.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1BmyjwlqeBYrC8cPIDaesaqWA6I68GBDd/view?usp=sharing"
    },
    {
        id: 47,
        name: "COMPLETE ANCIENT HISTORY HINDI BATCH 3 PARMAR SIR 2025.",
        price: 20,
        description: "COMPLETE ANCIENT HISTORY HINDI BATCH 3 2025 BY PARMAR CLASSES.",
        image: "https://i.imgur.com/aTx4zAm.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1e1TPuh_xHQ8n6SvFOc8MjWXddMyYzNvV/view?usp=sharing"
    },
    // {
    //     id: 48,
    //     name: "INDUS VALLEY CIVILISATION ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "INDUS VALLEY CIVILISATION ENGLISH BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/yijurqy.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1mtNONAgy3jk7BitTNiT0wK2BslxRr9Cw/view?usp=sharing"
    // },
    // {
    //     id: 49,
    //     name: "INDUS VALLEY CIVILISATION HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "INDUS VALLEY CIVILISATION HINDI BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/VNEIpmX.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1Vo1OXv1y2EUQG3391jNOULnJL_wArwLb/view?usp=sharing"
    // },
    // {
    //     id: 50,
    //     name: "VEDIC AGE ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "VEDIC AGE ENGLISH HISTORY BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/nZvr1US.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/16XbCfUMLoApCME7FtzY7hwWbpfSdv69Z/view?usp=sharing"
    // },
    // {
    //     id: 51,
    //     name: "VEDIC AGE HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "VEDIC AGE HINDI HISTORY BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/ZA8joje.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1NUJ29fHUFkN7_GC0O0jR6IbSn1Y1Gkwf/view?usp=sharing"
    // },
    // {
    //     id: 52,
    //     name: "BUDHISM AND JAINISM ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "BUDHISM AND JAINISM ENGLISH HISTORY BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/UMvbOYs.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1YLlnjzkxNHSI_539EDBgOdIjSEfyY-jx/view?usp=sharing"
    // },
    // {
    //     id: 53,
    //     name: "BUDHISM AND JAINISM HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "BUDHISM AND JAINISM HINDI HISTORY BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/rTjUubI.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1OESh5uZvvfmWSwNUTvQPcNg-Ek_eRAwJ/view?usp=sharing"
    // },
    // {
    //     id: 54,
    //     name: "MAHAJANPADAS & MAGADHA ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "MAHAJANPADAS & MAGADHA ENGLISH HISTORY BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/BafZfNs.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1TbElC3oubJAQ13ulCzxutOpvOquFAaIq/view?usp=sharing"
    // },
    // {
    //     id: 55,
    //     name: "MAHAJANPADAS & MAGADHA HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "MAHAJANPADAS & MAGADHA HINDI HISTORY BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/1OIAksf.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1c2gNJIdu7DDUC483QPMcQpLu7OX2XpUi/view?usp=sharing"
    // },
    // {
    //     id: 56,
    //     name: "MAURYAS ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "MAURYAS ENGLISH HISTORY BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/A75sygR.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1hdwtGz8ZpDuvIBpUQQ00wYOkezU7h6Lq/view?usp=sharing"
    // },
    // {
    //     id: 57,
    //     name: "MAURYAS HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "MAURYAS HINDI HISTORY BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/WsYWg2C.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1cJPdPxvi2Ey84yoSgBwTSjxL-u_vQaTa/view?usp=sharing"
    // },
    // {
    //     id: 58,
    //     name: "POST MAURYA DYNASTIES ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "POST MAURYA DYNASTIES ENGLISH HISTORY BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/Gm6xKgY.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1jjeB3np2CSA7HvK3z31AV-X5VDiusRYD/view?usp=sharing"
    // },
    // {
    //     id: 59,
    //     name: "POST MAURYA DYNASTIES HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "POST MAURYA DYNASTIES HINDI BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/S33WsZT.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1tJNR9U2mcDE30cL56Gqsvuj6-X9u_2yS/view?usp=sharing"
    // },
    // {
    //     id: 60,
    //     name: "SANGAM AGE ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "SANGAM AGE ENGLISH BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/j9xkud8.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1uYsVfEqlOWEoDQpB0ZO9xl2f6M98M7bb/view?usp=sharing"
    // },
    // {
    //     id: 61,
    //     name: "SANGAM AGE HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "SANGAM AGE HINDI BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/1fyRae0.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1wHZQofuld8SknzMl21Fs7naAD5wRJvOi/view?usp=sharing"
    // },
    // {
    //     id: 62,
    //     name: "GUPTA ERA ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "GUPTA ERA ENGLISH BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/OD8Ec8q.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/10Nlfnvk3gN-aYHA8FCAAF0wx78X1ZKUD/view?usp=sharing"
    // },
    // {
    //     id: 63,
    //     name: "GUPTA ERA HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "GUPTA ERA HINDI BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/SIO8m6c.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1e_NVmIPR41ZTf02KTjLj3jDb8cfHNiFU/view?usp=sharing"
    // },
    // {
    //     id: 64,
    //     name: "POST GUPTA/CHALUKYAS ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "POST GUPTA/CHALUKYAS ENGLISH BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/oooJvi7.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1H65zNFaEyg9ATrcO6PT-Pz7zAgU5ArFr/view?usp=sharing"
    // },
    // {
    //     id: 65,
    //     name: "POST GUPTA/CHALUKYAS HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "POST GUPTA/CHALUKYAS HINDI BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/ZZn4Oa5.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1iTLb_PnF9JJst23jTi_y_po73vL1E-9f/view?usp=sharing"
    // },
    // {
    //     id: 66,
    //     name: "CHOLAS AND TRIPARTITE ENGLISH ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "CHOLAS AND TRIPARTITE ENGLISH BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/TCXWErr.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1ZTJBs9MOLGgY817wGIcJFjayCG0VhE4e/view?usp=sharing"
    // },
    // {
    //     id: 67,
    //     name: "CHOLAS AND TRIPARTITE HINDI ANCIENT HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     price: 5,
    //     description: "CHOLAS AND TRIPARTITE HINDI BATCH 3 2025 PDF BY PARMAR CLASSES",
    //     image: "https://i.imgur.com/UgKKpNl.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1wd7RE5py4pRj5_u_Yk-amfOGCnrtbhCD/view?usp=sharing"
    // },
    {
        id: 68,
        name: "COMPLETE MEDIEVAL HISTORY ENGLISH BATCH 3 PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE MEDIEVAL HISTORY ENGLISH BATCH 3 2025",
        image: "https://i.imgur.com/p9REZRh.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1qe8Pne-htEqPsQl2qwpMhU8sctSnWK2Q/view?usp=sharing"
    },
    {
        id: 69,
        name: "COMPLETE MEDIEVAL HISTORY HINDI BATCH 3 PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE MEDIEVAL HISTORY HINDI BATCH 3 2025",
        image: "https://i.imgur.com/8wDsIA2.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1FU5gK0suK6Z7ctZ7X4xFVVlPRaSgRyQm/view?usp=sharing"
    },
    {
        id: 96,
        name: "COMPLETE MORDERN HISTORY ENGLISH BATCH 3 PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE MEDIEVAL HISTORY ENGLISH BATCH 3 2025",
        image: "https://i.imgur.com/myuImGu.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1pdlMu-9OHSrr7rb_MCzH7t7X424oeXbV/view?usp=sharing"
    },
    {
        id: 97,
        name: "COMPLETE MORDERN HISTORY HINDI BATCH 3 PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE MEDIEVAL HISTORY HINDI BATCH 3 2025",
        image: "https://i.imgur.com/UAvhuqb.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1gQTa7CbKx6mF9u9SMtY-gKwAUI9WOChN/view?usp=sharing"
    },
    // {
    //     id: 70,
    //     name: "DELHI SULTANATE PART 2 ENGLISH MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "DELHI SULTANATE PART 2 ENGLISH MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/D8tLZZI.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1xaBnKkwOcqMb5ksrblZQTCSjDNPauJ7n/view?usp=sharing"
    // },
    // {
    //     id: 71,
    //     name: "DELHI SULTANATE PART 2 HINDI MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "DELHI SULTANATE PART 2 HINDI MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/UsjJkSw.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1PZ1hLbW5tBH8L4hk4qodrdTEDcabiXyy/view?usp=sharing"
    // },
    // {
    //     id: 72,
    //     name: "VIJAYNAGARA & BAHMANI ENGLISH MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "VIJAYNAGARA & BAHMANI ENGLISH MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/eZhF5fC.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1BFxDVt5TgUFXb1k15d7byujg-lRBBjCD/view?usp=sharing"
    // },
    // {
    //     id: 73,
    //     name: "VIJAYNAGARA & BAHMANI HINDI MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "VIJAYNAGARA & BAHMANI HINDI MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/Hsa84g3.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1Tz6ITIwnR_-JZRXY5MYwCEa7kw0LbshJ/view?usp=sharing"
    // },
    // {
    //     id: 74,
    //     name: "MUGHAL EMPIRE PART 1 ENGLISH MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "MUGHAL EMPIRE PART 1 ENGLISH MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/DDwzVT1.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1o0WhSUXYniekUpHa35OvRTZamr7nr0ja/view?usp=sharing"
    // },
    // {
    //     id: 75,
    //     name: "MUGHAL EMPIRE PART 1 HINDI MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "MUGHAL EMPIRE PART 1 HINDI MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/wyDnCzg.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/154XQhcExChTKfB43pagUv0Fm4PqD6p79/view?usp=sharing"
    // },
    // {
    //     id: 76,
    //     name: "MUGHAL EMPIRE PART 2 ENGLISH MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "MUGHAL EMPIRE PART 2 ENGLISH MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/ihcQ1vi.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/13Nj4SSgGb3sVagFmb15IXuMmXkaF__TV/view?usp=sharing"
    // },
    // {
    //     id: 77,
    //     name: "MUGHAL EMPIRE PART 2 HINDI MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "MUGHAL EMPIRE PART 2 HINDI MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/ESticdl.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1GfHt928AR99h-tr3Vc5_vdhc2ouvUkdf/view?usp=sharing"
    // },
    // {
    //     id: 78,
    //     name: "MARATHAS ENGLISH MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "MARATHAS ENGLISH MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/lrmNW8d.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1bhsWDQ6bGfQU0bV46LxFI0cezBYpSE3K/view?usp=sharing"
    // },
    // {
    //     id: 79,
    //     name: "MARATHAS HINDI MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "MARATHAS HINDI MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/iuNc5Qr.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1tPLlY7oPzh3pQwE_pqwlvCubSIP--GKQ/view?usp=sharing"
    // },
    // {
    //     id: 80,
    //     name: "BHAKTI & SUFI MOVEMENT ENGLISH MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "BHAKTI & SUFI MOVEMENT ENGLISH MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/dpdZ9l4.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1zAytsFWjCb_Pil6g96REtDQOHCMq-i4L/view?usp=sharing"
    // },
    // {
    //     id: 81,
    //     name: "BHAKTI & SUFI MOVEMENT HINDI MEDIEVAL HISTORY BATCH 3",
    //     price: 5,
    //     description: "BHAKTI & SUFI MOVEMENT HINDI MEDIEVAL HISTORY BATCH 3 2025 BY PARMAR CLASSES.",
    //     image: "https://i.imgur.com/qZxgAFH.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1IErnN3LU5zJNKodfjUeUiEOYg-xaXyym/view?usp=sharing"
    // },
    {
        id: 82,
        name: "COMPLETE GEOGRAPHY ENGLISH PARMAR SIR 2025 BATCH 3 ",
        price: 25,
        description: "Complete Geography English Parmar BATCH 3 2025",
        image: "https://i.imgur.com/rqQex6E.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1xvJ27NkHLo5EkxWDnX1a5-krAgaSap4B/view?usp=sharing"
    },
    // {
    //     id: 83,
    //     name: "Geography Part 2 Lecture 11-20 English Parmar BATCH 3",
    //     price: 15,
    //     description: "Geography Part 2 Lecture 11-20 English Parmar BATCH 3",
    //     image: "https://i.imgur.com/IHgZ7KL.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1Q04Didmhe5Zi1diU5OLVwAFh_T-NPDbr/view?usp=sharing"
    // },
    {
        id: 84,
        name: "COMPLETE GEOGRAPHY HINDI BATCH 3 PARMAR SIR 2025",
        price: 25,
        description: "Complete Geography Hindi Parmar BATCH 3 2025",
        image: "https://i.imgur.com/dcp98fy.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1GwRtDAb5Dm-QKXpQoR3MoLi06tSyo1Fi/view?usp=sharing"
    },
    // {
    //     id: 85,
    //     name: "Geography Part 2 Lecture 11-20 Hindi Parmar BATCH 3",
    //     price: 15,
    //     description: "Geography Part 2 Lecture 11-20 Hindi Parmar BATCH 3",
    //     image: "https://i.imgur.com/KAvbUR6.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1gTe_n4NnvkiuSHWfbXbWvXWnjX0B7pyL/view?usp=sharing"
    // },
    // {
    //     id: 86,
    //     name: "CLASSICAL DANCE Hindi Parmar BATCH 3",
    //     price: 5,
    //     description: "CLASSICAL DANCE Hindi Parmar BATCH 3",
    //     image: "https://i.imgur.com/Rr44n9K.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1XFVslmt6n2BoyxvuHhgxSkZMvzxzouH0/view?usp=sharing"
    // },
    // {
    //     id: 87,
    //     name: "FESTIVALS OF INDIA ENGLISH Parmar BATCH 3",
    //     price: 5,
    //     description: "FESTIVALS OF INDIA ENGLISH Parmar BATCH 3",
    //     image: "https://i.imgur.com/lZ0KSW8.png?maxwidth=520&shape=thumb&fidelity=low",
    //     downloadLink: "https://drive.google.com/file/d/1XlNj_sCqub34uQ8OFBlPO19t8CaqcK0C/view?usp=sharing"
    // },
    {
        id: 88,
        name: "COMPLETE ECONOMICS ENG PARMAR SIR 2025 CLASSES BATCH 3",
        price: 15,
        description: "ECONOMICS ENG PARMAR CLASSES BATCH 3 LEC 1-10",
        image: "https://i.imgur.com/FRVj4if.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1ijJpmYVcizI2IfCD5pMHNCfYipqqM0ZE/view?usp=sharing"
    },
    {
        id: 89,
        name: "COMPLETE ECONOMICS HINDI PARMAR SIR 2025 CLASSES BATCH 3",
        price: 15,
        description: "ECONOMICS HINDI PARMAR CLASSES BATCH 3 LEC 1-10",
        image: "https://i.imgur.com/xwNCbsp.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/14UdhjZ2336C625oLLq7CNVkiBY3SUZXJ/view?usp=sharing"
    },
    {
        id: 90,
        name: "COMPLETE PHYSICS BATCH 3 ENGLISH PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE PHYSICS ENGLISH PARMAR CLASSES BATCH 3 2025",
        image: "https://i.imgur.com/WKiW2aw.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/180jRbROyq9b4eiwIi-CVpRq-9GsPMtJx/view?usp=sharing"
    },
    {
        id: 91,
        name: "COMPLETE PHYSICS BATCH 3 HINDI PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE PHYSICS HINDI PARMAR CLASSES BATCH 3 2025",
        image: "https://i.imgur.com/uh7PEsu.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1h1mxrknypKQ6Zm0SuMPfMWj5ImtnZeQM/view?usp=sharing"
    },
    {
        id: 92,
        name: "COMPLETE BIOLOGY BATCH 3 ENGLISH PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE BIOLOGY ENGLISH PARMAR CLASSES BATCH 3 2025",
        image: "https://i.imgur.com/HiY6Z5B.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1Mj8TH7ckr1lvXHVSBtlXAppwOWuMdt5n/view?usp=sharing"
    },
    {
        id: 93,
        name: "COMPLETE BIOLOGY BATCH 3 HINDI PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE BIOLOGY HINDI PARMAR CLASSES BATCH 3 2025",
        image: "https://i.imgur.com/77NMVEp.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1jqTU7WM3c46werIafdp-VHiqRCxD3tfJ/view?usp=sharing"
    },
    {
        id: 94,
        name: "COMPLETE CHEMISTRY BATCH 3 ENGLISH PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE CHEMISTRY ENGLISH PARMAR CLASSES BATCH 3 2025",
        image: "https://i.imgur.com/yLkgjpu.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1rChQ7LkxJMMkuyjR-RfwQJsctmI9G7Ck/view?usp=sharing"
    },
    {
        id: 95,
        name: "COMPLETE CHEMISTRY BATCH 3 HINDI PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE CHEMISTRY HINDI PARMAR CLASSES BATCH 3 2025",
        image: "https://i.imgur.com/6Goclwh.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1IIDotv70xNAhOtxW4dOkkAo3ZM6Xx7Mn/view?usp=sharing"
    },
    {
        id: 98,
        name: "COMPLETE POLITY BATCH 3 ENGLISH PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE POLITY ENGLISH PARMAR CLASSES BATCH 3 2025",
        image: "https://i.imgur.com/dsVzTOT.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1AxE9uneoybcezExncIg-N_FGwaFRk8gO/view?usp=sharing"
    },
    {
        id: 99,
        name: "COMPLETE POLITY BATCH 3 HINDI PARMAR SIR 2025",
        price: 20,
        description: "COMPLETE POLITY HINDI PARMAR CLASSES BATCH 3 2025",
        image: "https://i.imgur.com/8mbzDy1.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1M3nir2LhdRxmII3GVxEHmSPRrBkTWVWk/view?usp=sharing"
    },
    {
        id: 13,
        name: "Computer 1500+ Previous Year SSC & TCS Questions",
        price: 10,
        description: "Complete 1500+ Previous Year for SSC & TCS Questions",
        image: "https://i.imgur.com/Vf1NCjk.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/file/d/1XVBEe-jCCyU-OGDYXy8swMJCcqLhR99C/view?usp=sharing"
    },
    {
        id: 100,
        name: "Indian Classical Dance Static Flowchart",
        price: 5,
        description: "Indian Classical Dance StaticGK Flowchart",
        image: "https://i.imgur.com/VMrJm9g.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/uc?export=download&id=1icU7oPpUjZXziZHXDahyyEjEYYM-oR2W"
    },
    {
        id: 101,
        name: "Indian Folk Dance Static Flowchart",
        price: 5,
        description: "Indian Folk Dance StaticGK Flowchart",
        image: "https://i.imgur.com/pEkNICM.png?maxwidth=520&shape=thumb&fidelity=low",
        downloadLink: "https://drive.google.com/uc?export=download&id=1BYJY23HW6DO2vCk5FZdEC2OyL3DPHQ7_"
    },
];

const EbookStore = ({ cart, setCart, darkMode }) => {
    const [user, setUser] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredEbooks, setFilteredEbooks] = useState(ebooks);
    const [showRecommendations, setShowRecommendations] = useState(false);
    const [activeFilter, setActiveFilter] = useState("All");
    const recommendedSearches = ["All", "PARMAR SIR", "PYQ 2024", "Static Flowchart", "PHYSICS", "CHEMISTRY", "BIOLOGY", "HISTORY", "ECONOMICS", "STATIC GK"];
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (activeFilter === "All") {
            setFilteredEbooks(ebooks);
        } else {
            const filtered = ebooks.filter(ebook =>
                ebook.name.toLowerCase().includes(activeFilter.toLowerCase())
            );
            setFilteredEbooks(filtered);
        }
        setSearchQuery(""); // Clear search when filter changes
    }, [activeFilter]);

    const addToCart = (ebook) => {
        if (!user) {
            alert("Please login to add items to cart!");
            return;
        }
        if (cart.some((item) => item.id === ebook.id)) {
            alert("This book is already in your cart!");
            return;
        }
        setCart([...cart, ebook]);
        alert(`${ebook.name} added to cart!`);
        // console.log("ebook", ebook);
    };

    // 1. Add this to your cart page (or app initialization)
    useEffect(() => {
        const warmUpServer = async () => {
            try {
                await axios.get('https://cashfree-backend-1k3q.onrender.com/warmup', {
                    timeout: 3000 // Don't wait too long
                });
            } catch (error) {
                console.log('Server warming in background...');
            }
        };
        warmUpServer();
    }, []);

    return (
        <div style={{ padding: "20px", marginBottom: "50vh" }}>
            <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px" }}>
                E-Books Store 📚
            </h2>
            {/* Category filter chips */}
            <div style={styles.filterContainer}>
                {recommendedSearches.map((filter) => (
                    <button
                        key={filter}
                        onClick={() => setActiveFilter(filter)}
                        style={{
                            ...styles.filterButton,
                            ...(activeFilter === filter ? styles.activeFilter : {})
                        }}
                    >
                        {filter}
                    </button>
                ))}
            </div>
            <div style={styles.searchContainer}>
                <input
                    type="text"
                    placeholder="Search ebooks..."
                    value={searchQuery}
                    onChange={(e) => {
                        const query = e.target.value;
                        setSearchQuery(query);
                        const filtered = ebooks.filter(ebook =>
                            ebook.name.toLowerCase().includes(query.toLowerCase())
                        );
                        setFilteredEbooks(filtered);
                        if (query.trim() === '') {
                            setShowRecommendations(true);
                        } else {
                            setShowRecommendations(false);
                        }
                    }}
                    onFocus={() => setShowRecommendations(true)}
                    onBlur={() => setTimeout(() => setShowRecommendations(false), 200)}
                    style={styles.searchInput}
                />
                {showRecommendations && recommendedSearches.length > 0 && (
                    <div style={styles.recommendationsContainer}>
                        {recommendedSearches.map((term, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    setSearchQuery(term);
                                    const filtered = ebooks.filter(ebook =>
                                        ebook.name.toLowerCase().includes(term.toLowerCase())
                                    );
                                    setFilteredEbooks(filtered);
                                    setShowRecommendations(false);
                                }}
                                style={styles.recommendationItem}
                            >
                                {term}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', marginBottom: "25px" }}>
                {/* {filteredEbooks.map((ebook) => (
                    <PreviewCard
                        key={ebook.id}
                        card={{
                            id: ebook.id,
                            title: ebook.name,
                            description: ebook.description,
                            image: ebook.image,
                            link: "#",
                        }}
                        darkMode={darkMode}
                        price={ebook.price}
                        size="small"
                        addToCart={() => addToCart(ebook)}
                    />
                ))} */}
                {filteredEbooks.length > 0 ? (
                    filteredEbooks.map((ebook) => (
                        <PreviewCard
                            key={ebook.id}
                            card={{
                                id: ebook.id,
                                title: ebook.name,
                                description: ebook.description,
                                image: ebook.image,
                                link: "#",
                            }}
                            darkMode={darkMode}
                            price={ebook.price}
                            size="small"
                            addToCart={() => addToCart(ebook)}
                        />
                    ))
                ) : (
                    <p>No ebooks found in this category.</p>
                )}
            </div>

            <div
                style={{
                    position: "fixed",
                    bottom: "15px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "90%",
                    maxWidth: "400px",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                }}
            >
                <button
                    style={{
                        flex: 1,
                        backgroundColor: "#28a745",
                        color: "white",
                        padding: "12px",
                        borderRadius: "8px",
                        border: "none",
                        fontSize: "16px",
                        cursor: "pointer",
                        textAlign: "center",
                        fontWeight: "bold",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() => navigate("/downloads")}
                >
                    📥 My Downloads
                </button>

                <button
                    style={{
                        flex: 1,
                        backgroundColor: cart.length === 0 ? "gray" : "#007bff",
                        color: "white",
                        padding: "12px",
                        borderRadius: "8px",
                        border: "none",
                        fontSize: "16px",
                        cursor: cart.length === 0 ? "not-allowed" : "pointer",
                        textAlign: "center",
                        fontWeight: "bold",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() => {
                        if (cart.length === 0) {
                            alert("Your cart is empty! Add items before viewing your cart.");
                        } else {
                            navigate("/ecart");
                        }
                    }}
                    disabled={cart.length === 0}
                >
                    🛒 Go to Cart ({cart.length})
                </button>
            </div>
        </div>
    );
};

// const EbookStore = ({ cart, setCart, darkMode }) => {
//     const [user, setUser] = useState(null);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const unsubscribe = auth.onAuthStateChanged((currentUser) => {
//             setUser(currentUser);
//         });
//         return () => unsubscribe();
//     }, []);

//     const addToCart = (ebook) => {
//         if (!user) {
//             alert("Please login to add items to cart!");
//             return;
//         }
//         if (cart.some((item) => item.id === ebook.id)) {
//             alert("This book is already in your cart!");
//             return;
//         }
//         setCart([...cart, ebook]);
//         alert(`${ebook.name} added to cart!`);
//     };

//     return (
//         <div style={{ padding: "20px" }}>
//             <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px" }}>
//                 E-Books Store 📚
//             </h2>
//             <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', marginBottom: "25px" }}>
//                 {ebooks.map((ebook) => (
//                     <PreviewCard
//                         key={ebook.id}
//                         card={{
//                             id: ebook.id,
//                             title: ebook.name,
//                             description: ebook.description,
//                             image: ebook.image,
//                             link: "#",
//                         }}
//                         darkMode={darkMode}
//                         price={ebook.price}
//                         size="small"
//                         addToCart={() => addToCart(ebook)}
//                     />
//                 ))}
//             </div>

//             {/* <button
//                 className="button n-button"
//                 style={{ marginTop: "20px" }}
//                 onClick={() => {
//                     if (cart.length === 0) {
//                         alert("Your cart is empty! Add items before viewing your cart.");
//                     } else {
//                         navigate("/ecart");
//                     }
//                 }}
//             >
//            {/* ✅ Button Container for My Downloads & Go to Cart */}
//             <div
//                 style={{
//                     position: "fixed",
//                     bottom: "15px",
//                     left: "50%",
//                     transform: "translateX(-50%)",
//                     width: "90%",
//                     maxWidth: "400px",
//                     display: "flex",
//                     justifyContent: "space-between",
//                     gap: "10px",
//                 }}
//             >
//                 {/* ✅ My Downloads Button (Left) */}
//                 <button
//                     style={{
//                         flex: 1,
//                         backgroundColor: "#28a745",
//                         color: "white",
//                         padding: "12px",
//                         borderRadius: "8px",
//                         border: "none",
//                         fontSize: "16px",
//                         cursor: "pointer",
//                         textAlign: "center",
//                         fontWeight: "bold",
//                         boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//                     }}
//                     onClick={() => navigate("/downloads")}
//                 >
//                     📥 My Downloads
//                 </button>

//                 {/* ✅ Go to Cart Button (Right) */}
//                 <button
//                     style={{
//                         flex: 1,
//                         backgroundColor: cart.length === 0 ? "gray" : "#007bff",
//                         color: "white",
//                         padding: "12px",
//                         borderRadius: "8px",
//                         border: "none",
//                         fontSize: "16px",
//                         cursor: cart.length === 0 ? "not-allowed" : "pointer",
//                         textAlign: "center",
//                         fontWeight: "bold",
//                         boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//                     }}
//                     onClick={() => {
//                         if (cart.length === 0) {
//                             alert("Your cart is empty! Add items before viewing your cart.");
//                         } else {
//                             navigate("/ecart");
//                         }
//                     }}
//                     disabled={cart.length === 0}
//                 >
//                     🛒 Go to Cart ({cart.length})
//                 </button>
//             </div>

//         </div>
//     );
// };

const styles = {
    filterContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginBottom: '20px',
    },
    filterButton: {
        padding: '8px 16px',
        borderRadius: '20px',
        border: '1px solid #ddd',
        background: '#f5f5f5',
        cursor: 'pointer',
        fontSize: '14px',
        transition: 'all 0.3s ease',
    },
    activeFilter: {
        background: '#007bff',
        color: 'white',
        borderColor: '#007bff',
    },
    searchContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        marginBottom: "20px",
        width: "100%",
    },
    searchInput: {
        padding: "12px",
        width: "80%",
        borderRadius: "8px",
        border: "2px solid #007bff",
        fontSize: "16px",
        outline: "none",
        transition: "0.3s",
        boxShadow: "0 2px 5px rgba(0, 123, 255, 0.2)"
    },
    recommendationsContainer: {
        position: "absolute",
        width: "80%",
        background: "#fff",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        marginTop: "50px",
        zIndex: 10,
        overflowY: "auto",
        maxHeight: "200px",
    },
    recommendationItem: {
        padding: "12px",
        cursor: "pointer",
        fontSize: "14px",
        borderBottom: "1px solid #f0f0f0",
        transition: "0.3s",
        textAlign: "left",
        color: "#333",
        backgroundColor: "#fff"
    },
    recommendationItemHover: {
        backgroundColor: "#007bff",
        color: "#fff"
    }
};

export default EbookStore;
