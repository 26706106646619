// import React, { useState, useEffect } from "react";
// import { db, auth } from "../../firebase/firebaseConfig";
// import { collection, query, where, getDocs } from "firebase/firestore";

// const MyDownloads = () => {
//     const [downloads, setDownloads] = useState([]);
//     const [loading, setLoading] = useState(true); 

//     useEffect(() => {
//         const fetchDownloads = async () => {
//             setLoading(true);
//             const user = auth.currentUser;

//             if (!user) {
//                 alert("Please login to view your downloads.");
//                 setLoading(false);
//                 return;
//             }

//             const ordersQuery = query(collection(db, "orders"), where("status", "==", "paid"));
//             const querySnapshot = await getDocs(ordersQuery);

//             let userDownloads = [];
//             querySnapshot.forEach((doc) => {
//                 const orderData = doc.data();
//                 if (orderData.downloads) {
//                     userDownloads = [...userDownloads, ...orderData.downloads];
//                 }
//             });

//             setDownloads(userDownloads);
//             setLoading(false);
//         };

//         fetchDownloads();
//     }, []);

//     return (
//         <div style={{ padding: "20px" }}>
//             <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>📥 My Downloads</h2>

//             {loading ? (
//                 <p>Loading...</p>
//             ) : downloads.length === 0 ? (
//                 <p>You have no purchased e-books.</p>
//             ) : (
//                 <ul style={{ listStyle: "none", padding: 0 }}>
//                     {downloads.map((download, index) => (
//                         <li key={index} style={{ marginBottom: "10px" }}>
//                             <p style={{ fontWeight: "bold", fontSize: "16px" }}>{download.name}</p>
//                             <a
//                                 href={download.downloadLink}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 style={{
//                                     color: "blue",
//                                     textDecoration: "underline",
//                                     fontSize: "14px"
//                                 }}
//                             >
//                                 Download E-Book
//                             </a>
//                         </li>
//                     ))}
//                 </ul>
//             )}
//         </div>
//     );
// };

// export default MyDownloads;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { db, auth } from "../../firebase/firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { themeContext } from "../../Context";

const MyDownloads = () => {
    const [downloads, setDownloads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    // useEffect(() => {
    //     // Function to fetch downloads
    //     const fetchDownloads = async (user) => {
    //         try {
    //             setLoading(true);
    //             setError("");

    //             // Fetch the user document
    //             const userDocRef = doc(db, "users", user.uid);
    //             const userDoc = await getDoc(userDocRef);

    //             if (userDoc.exists()) {
    //                 const userData = userDoc.data();
    //                 const orders = userData.orders || {}; // Get the orders field (default to empty object)

    //                 // Filter paid orders and extract downloads
    //                 let userDownloads = [];
    //                 for (const orderId in orders) {
    //                     if (orders[orderId].status === "paid" && orders[orderId].cart) {
    //                         userDownloads = [...userDownloads, ...orders[orderId].cart];
    //                     }
    //                 }

    //                 setDownloads(userDownloads);
    //             } else {
    //                 setError("No user data found.");
    //             }
    //         } catch (err) {
    //             console.error("Error fetching downloads:", err);
    //             setError("Failed to load downloads. Please try again.");
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     // Listen for authentication state change
    //     const unsubscribe = auth.onAuthStateChanged((user) => {
    //         if (user) {
    //             fetchDownloads(user);
    //         } else {
    //             setDownloads([]); // Clear downloads if user logs out
    //             setLoading(false);
    //             setError("Please login to view your downloads.");
    //         }
    //     });

    //     return () => unsubscribe(); // Cleanup on unmount
    // }, []);

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    useEffect(() => {
        const fetchDownloads = async (user) => {
            try {
                setLoading(true);
                setError("");

                // Fetch user document
                const userDocRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const orders = userData.orders || {}; // Get all orders

                    let latestOrderId = null;
                    let userDownloads = [];

                    // Convert Firestore timestamps and sort by createdAt
                    const sortedOrders = Object.entries(orders)
                        .map(([orderId, order]) => ({
                            orderId,
                            ...order,
                            createdAt: order.createdAt?.toDate ? order.createdAt.toDate() : new Date(order.createdAt) // Convert Firestore timestamp
                        }))
                        .sort((a, b) => b.createdAt - a.createdAt); // Sort orders by most recent first

                    for (const order of sortedOrders) {
                        if (order.status === "paid") {
                            userDownloads = [...userDownloads, ...order.cart];
                        } else if (order.status === "pending" && !latestOrderId) {
                            latestOrderId = order.orderId; // Store the most recent pending order
                        }
                    }

                    // Verify only the latest pending order
                    if (latestOrderId) {
                        await verifyPayment(user.uid, latestOrderId);
                    }

                    setDownloads(userDownloads);
                } else {
                    setError("No user data found.");
                }
            } catch (err) {
                console.error("Error fetching downloads:", err);
                setError("Failed to load downloads. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        const verifyPayment = async (userId, orderId) => {
            try {
                const res = await axios.get(`https://cashfree-backend-1k3q.onrender.com/payment-status/${orderId}`);

                if (res.data && res.data.length > 0 && res.data[0].payment_status === "SUCCESS") {
                    // Update order status to paid and add download links
                    await updateDoc(doc(db, "users", userId), {
                        [`orders.${orderId}.status`]: "paid",
                    });
                    alert(`Payment verified for order ${orderId}. Your downloads have been added.`);
                    fetchDownloads({ uid: userId });  // Call fetchDownloads again with updated user data
                }
            } catch (error) {
                console.error(`Error verifying payment for order ${orderId}:`, error);
            }
        };

        // Listen for authentication state change
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                fetchDownloads(user);
            } else {
                setDownloads([]);
                setLoading(false);
                setError("Please login to view your downloads.");
            }
        });

        return () => unsubscribe();
    }, []);


    return (
        <div style={{
            padding: "20px",
            maxWidth: "800px",
            margin: "auto",
            fontFamily: "Arial, sans-serif",
            backgroundColor: darkMode ? "#1a1a1a" : "#f9f9f9",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            marginBottom: "60vh"
        }}>
            <h2 style={{
                fontSize: "26px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "20px",
            }}>
                📥 My Downloads
            </h2>

            {loading ? (
                <p style={{ textAlign: "center", fontSize: "18px", color: "#555" }}>
                    Loading...
                </p>
            ) : error ? (
                <p style={{ textAlign: "center", fontSize: "18px", color: "red" }}>
                    {error}
                </p>
            ) : downloads.length === 0 ? (
                <p style={{
                    textAlign: "center",
                    fontSize: "18px",
                    color: "gray",
                    padding: "10px"
                }}>
                    You have no purchased e-books. Please Refresh to Update Download.
                </p>
            ) : (
                <ul style={{ listStyle: "none", padding: 0 }}>
                    {downloads.map((download, index) => (
                        <li key={index} style={{
                            // backgroundColor: "#fff",
                            padding: "12px",
                            marginBottom: "10px",
                            borderRadius: "6px",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px"
                        }}>
                            <p style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                // color: "#333",
                                marginBottom: "5px"
                            }}>
                                {download.name}
                            </p>
                            <a
                                href={download.downloadLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: "inline-block",
                                    backgroundColor: "#007bff",
                                    color: "white",
                                    padding: "8px 12px",
                                    borderRadius: "4px",
                                    textAlign: "center",
                                    textDecoration: "none",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    transition: "background-color 0.3s ease"
                                }}
                                onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
                                onMouseOut={(e) => e.target.style.backgroundColor = "#007bff"}
                            >
                                📥 Download E-Book
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default MyDownloads;