import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { load } from '@cashfreepayments/cashfree-js';
// import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const Checkout = ({ cart }) => {
    // const [searchParams] = useSearchParams();
    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        landmark: "",
        pincode: "",
        locality: "",
        state: "",
    });
    const [loading, setLoading] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [paymentSessionId, setPaymentSessionId] = useState(null);
    const [orderId, setOrderId] = useState("");
    const [cashfree, setCashfree] = useState(null);
    // const navigate = useNavigate();

    // Initialize Cashfree SDK
    useEffect(() => {
        const initializeCashfree = async () => {
            try {
                const cashfreeInstance = await load({
                    mode: "production",
                });
                setCashfree(cashfreeInstance);
            } catch (error) {
                console.error("Error initializing Cashfree:", error);
                alert("Failed to initialize payment gateway");
            }
        };
        initializeCashfree();
    }, []);

    // Handle form field changes
    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };


    // Validate form
    const validateForm = () => {
        if (!form.name || !form.email || !form.phone || !form.address || !form.city || !form.pincode || !form.state || !form.landmark) {
            alert("Please fill out all required fields.");
            return false;
        }
        if (!/^\d{10}$/.test(form.phone)) {
            alert("Please enter a valid 10-digit phone number.");
            return false;
        }
        if (!/^\d{6}$/.test(form.pincode)) {
            alert("Please enter a valid 6-digit pincode.");
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(form.email)) {
            alert("Please enter a valid email address.");
            return false;
        }
        return true;
    };

    // Create an order in Firestore and return the order ID
    const createOrderInFirestore = async () => {
        try {
            const totalAmount = cart.reduce((sum, item) => sum + item.price, 0);
            const orderRef = await addDoc(collection(db, "orders"), {
                ...form,
                cart,
                totalAmount,
                status: "pending",
                createdAt: new Date(),
            });
            return orderRef.id;
        } catch (error) {
            console.error("Error storing order:", error);
            alert("Error creating order. Please try again.");
            return null;
        }
    };

    // Create a payment session with Cashfree
    const createPaymentSession = async (orderId) => {
        try {
            setLoading(true);
            const response = await axios.post('https://cashfree-backend-1k3q.onrender.com/create-payment-session', {
                orderId,
                orderAmount: cart.reduce((sum, item) => sum + item.price, 0),
                customerName: form.name,
                customerEmail: form.email,
                customerPhone: form.phone,
            });

            if (!response.data?.payment_session_id) {
                throw new Error('Payment session ID is missing from response');
            }

            setPaymentSessionId(`${response.data.payment_session_id}`);
            await updateDoc(doc(db, "orders", orderId), {
                paymentSessionId: response.data.payment_session_id,
            });

            setLoading(false);
            return response.data.payment_session_id;
        } catch (error) {
            console.error("Error creating payment session:", error);
            alert(`Payment processing failed: ${error.message}`);
            setLoading(false);
            throw error;
        }
    };

    const verifyPayment = async (orderId) => {
        // console.log("ORDER ID:", orderId);
        try {
            const res = await axios.get(`https://cashfree-backend-1k3q.onrender.com/payment-status/${orderId}`);
            // console.log("verifyPayment", res);
            if (res && res.data) {
                alert("Payment verified");
                setPaymentStatus(res.data);
                await updateDoc(doc(db, "orders", orderId), { status: "paid" });
            }
        } catch (error) {
            console.error("Error verifying payment:", error);
            alert("Failed to verify payment");
        }
    };

    const doPayment = async () => {
        try {
            if (!paymentSessionId) {
                console.log("Payment session ID not ready yet");
                return;
            }

            const checkoutOptions = {
                paymentSessionId: paymentSessionId,
                redirectTarget: "_modal",
            };

            cashfree.checkout(checkoutOptions).then((result) => {
                if (result.error) {
                    console.log("User has closed the popup or there is some payment error");
                    // console.log(result.error);
                }
                if (result.redirect) {
                    console.log("Payment will be redirected");
                }
                if (result.paymentDetails) {
                    console.log("Payment has been completed");
                    // console.log(result.paymentDetails.paymentMessage);
                    // console.log("ORDER ID:", orderId)
                    verifyPayment(orderId);
                }
            });
        } catch (error) {
            console.error("Error processing payment:", error);
            alert("Payment processing failed: " + error.message);
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        if (!form.name || !form.email || !form.phone || !form.address) {
            alert("Please fill out all fields.");
            return;
        }

        const orderID = await createOrderInFirestore();
        if (orderID) {
            setOrderId(`${orderID}`);
            const sessionId = await createPaymentSession(orderID);
            if (sessionId) {
                setPaymentSessionId(sessionId);
            }
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>Checkout</h2>
            <form style={{ display: "grid", gap: "12px" }} onSubmit={handleSubmit}>
                <input
                    name="name"
                    placeholder="Name"
                    required
                    onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                    onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="phone"
                    placeholder="Phone Number"
                    required
                    onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="address"
                    placeholder="Address (House No: and Street)"
                    required
                    onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="city"
                    placeholder="City/District/Town"
                    required onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />

                <input
                    name="pincode"
                    placeholder="Pincode"
                    required onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="state"
                    placeholder="State"
                    required onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="landmark"
                    placeholder="Landmark"
                    required onChange={handleChange}
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <input
                    name="locality"
                    placeholder="Locality (Optional)"
                    style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px" }}
                    onChange={handleChange}
                />

                <button
                    type="submit"
                    disabled={loading}
                    // style={{
                    //     backgroundColor: loading ? "#ccc" : "#4CAF50",
                    //     color: "white",
                    //     padding: "8px 16px",
                    //     borderRadius: "4px",
                    //     border: "none",
                    //     cursor: loading ? "not-allowed" : "pointer",
                    // }}
                    className="button n-button"
                >
                    {loading ? "Processing..." : "Submit Details"}
                </button>
            </form>
            {paymentSessionId && (
                <button onClick={doPayment} className="button n-button" style={{ marginTop: "20px" }}>
                    Pay Now
                </button>
            )}
            {paymentStatus && (
                <div style={{
                    marginTop: '20px',
                    padding: '15px',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '4px',
                    border: '1px solid #ddd'
                }}>
                    <h3>Payment Status</h3>
                    {/* {console.log("paymentStatus", paymentStatus)} */}
                    <p>Status: {paymentStatus[0].payment_status}</p>
                    {paymentStatus[0].order_amount && <p>{paymentStatus[0].payment_time}</p>}
                </div>
            )}
        </div>
    );
};

export default Checkout;