import "./App.css";
import React from "react";
import Navbar from "./components/navbar/Navbar";
import Intro from "./components/intro/Intro";
import Services from "./components/services/Services";
import Experience from "./components/experience/Experience";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonial from "./components/testimonial/Testimonial";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import DummyPage from "./pages/QuizPage";
import BlogPage from "./pages/BlogsPage";
import LoginPage from "./pages/Authentication/Login";
import SignUpPage from "./pages/Authentication/Signup";
import LoginPromptModal from "./pages/Authentication/LoginPromptModal"; // Import your modal

import { themeContext } from "./Context";
import { useContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./firebase/AuthListener"; // Import the AuthListener to start it
import HomePage from "./pages/homepage/HomePage";
import ScrollToTop from "./utilities/scrollToTop";
import ContactUs from "./pages/ContactUs";
import TermsAndConditions from "./pages/Terms&Conditions";
import CancellationRefundPolicy from "./pages/CancellationRefundPolicy";

import WhatsAppButton from "./components/whatsappBtn/whatsappfix";
// import Summarizer from "./pages/SummarizePage";

import useLoginPromptAuth from "./utilities/LoginPromptAuth";
import Store from "./components/store/store";
import Cart from "./components/store/cart";
import Checkout from "./components/store/checkout";
import AboutUs from "./pages/AboutUsPage";

//E-BOOKS
import EbookStore from "./components/store/EbookStore";
import Ecart from "./components/store/Ecart";
import EbookCheckout from "./components/store/EbookCheckout";
import MyDownloads from "./components/store/MyDownloads";

//Maths Power
import MathPowerGame from "./utilities/MathPower";

function App() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const [cart, setCart] = useState([]); // Initialize cart state

  const { isModalOpen, closeModal } = useLoginPromptAuth();



  return (
    <Router>
      <ScrollToTop />
      <div
        className="App"
        style={{
          background: darkMode ? "black" : "",
          color: darkMode ? "white" : "",
        }}
      >
        <Navbar />
        <LoginPromptModal isOpen={isModalOpen} onClose={closeModal} />
        <Routes>
          {/* Main home page components */}
          <Route path="/" element={<HomePage />} />
          <Route
            path="/founder"
            element={
              <>
                <Intro />
                <Services />
                <Experience />
                <Portfolio />
                <Testimonial />
                <Contact />
              </>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy darkMode={darkMode} />} />

          {/* <Route path="/summary" element={<Summarizer />} /> */}
          <Route path="/math-power" element={<MathPowerGame />} />
          {/* Route for DummyPage (Others page) */}
          <Route path="/quizCategory" element={<DummyPage />} />
          <Route path="/quizCategory/:quizType" element={<DummyPage />} />
          <Route path="/quizCategory/:quizType/:quizSet" element={<DummyPage />} />

          {/* Routes for BlogPage (new) */}
          <Route path="/blogsCategory" element={<BlogPage />} />
          <Route path="/blogsCategory/:blogType" element={<BlogPage />} />
          <Route path="/blogsCategory/:blogType/:blogId" element={<BlogPage />} />
          <Route path="/blogsCategory/:blogType/:blogId/:blogPost" element={<BlogPage />} /> {/* New route for blog posts */}

          {/*App.js Routes for Store (new) */}
          <Route path="/store" element={<Store cart={cart} setCart={setCart} />} />
          <Route path="/cart" element={<Cart cart={cart} setCart={setCart} />} />
          <Route path="/checkout" element={<Checkout cart={cart} />} />

          {/*App.js Routes for E-Book Store */}
          <Route path="/ebooks" element={<EbookStore cart={cart} setCart={setCart} />} />
          <Route path="/ecart" element={<Ecart cart={cart} setCart={setCart} />} />
          <Route path="/echeckout" element={<EbookCheckout cart={cart} />} />
          <Route path="/downloads" element={<MyDownloads />} />

        </Routes>
        <Footer />

        <WhatsAppButton /> {/* WhatsApp floating button */}
      </div>
    </Router>
  );
}


export default App;