import React, { useContext } from 'react';
import "./HomePage.css";
import { themeContext } from '../../Context';
import { Helmet } from 'react-helmet'; // Import Helmet
import SliderSection from '../../components/slider/SliderSection';
import ExploreSection from '../../components/explore/ExploreSection';
import InfoCard from '../../components/infoCard/InfoCard';
import PreviewCard from "../../components/previewCard/PreviewCard";

// Mock data for slider and cards
const sliderData = [
    { id: 3, title: "Practice Geography PYQs", image: "https://s3-ap-south-1.amazonaws.com/ricedigitals3bucket/AUPortalContent/2021/08/04083303/Untitled-design-81.png", link: "/quizCategory/geography-pyq-ssc-questions-sscvidya" },
    { id: 1, title: "Current Affairs 2024", image: "https://blog.cafunsta.com/wp-content/uploads/2022/10/Monthly-Current-Affairs.png", link: "/quizCategory/monthly-current-affairs" },
    { id: 2, title: "Festivals SSC PYQ", image: "https://www.holidaymonk.com/wp-content/uploads/2022/04/Holi-Festival-of-Colors-1024x577.jpg", link: "/quizCategory/static-gk-pyq/21" },
];

// const cardData = [
//     { id: 1, title: "SSC Topicwise Quizzes", description: "Practice topic-wise quizzes for SSC exams.", link: "/quizCategory" },
//     { id: 2, title: "Featured Blogs", description: "Read insightful articles to boost your prep.", link: "/blogsCategory" },
//     {
//         id: 3, title: "Featured Blogs", description: "Read insightful articles on India's Five-Year Plans.", link: "/blogsCategory/polity/0/FiveYearPlans"
//     },
//     { id: 4, title: "Featured Blogs", description: "Read insightful articles on Prehistoric Periods.", link: "/blogsCategory/history/0/StoneAge" },
//     {
//         id: 5, title: "Featured Blogs", description: "Read insightful articles Constitutional Provisions.", link: "/blogsCategory/polity/0/ConstitutionalParts"
//     },

// ];

const cardData = [
    { id: 1, title: "SSC Topicwise Quizzes", description: "Practice topic-wise quizzes for SSC exams.", link: "/quizCategory", backgroundImage: "https://asts01.mahendras.org/024122021112350_2.jpg" },
    { id: 2, title: "Featured Blogs", description: "Read insightful articles to boost your prep.", link: "/blogsCategory", backgroundImage: "https://via.placeholder.com/300x200" },
    {
        id: 3, title: "Featured Blogs", description: "Read insightful articles on India's Five-Year Plans.", link: "/blogsCategory/polity/0/FiveYearPlans", backgroundImage: "https://via.placeholder.com/300x200"
    },
    { id: 4, title: "Featured Blogs", description: "Read insightful articles on Prehistoric Periods.", link: "/blogsCategory/history/0/StoneAge", backgroundImage: "https://via.placeholder.com/300x200" },
    {
        id: 5, title: "Featured Blogs", description: "Read insightful articles Constitutional Provisions.", link: "/blogsCategory/polity/0/ConstitutionalParts", backgroundImage: "https://via.placeholder.com/300x200"
    },
    {
        id: 6, title: "Parmar Sir Books and Authors", description: "Parmar Classes Books and Authors.", link: "blogsCategory/Static-Gk/0/books-authors", backgroundImage: "https://via.placeholder.com/300x200"
    },
];

const mts2024Data = [
    { id: 1, title: "Geography", description: "Practice SSC MTS 2024 previous year geography questions and boost your preparation.", link: "/quizCategory/geography-pyq-ssc-questions-sscvidya", backgroundImage: "https://asts01.mahendras.org/024122021112350_2.jpg" },
    { id: 2, title: "Polity", description: "Enhance your knowledge of Indian polity with important concepts and PYQs from SSC MTS 2024.", link: "/quizCategory/polity-pyq-sscvidya", backgroundImage: "https://via.placeholder.com/300x200" },
    { id: 3, title: "History", description: "Explore India's rich history with previous year questions and key historical events from SSC MTS 2024.", link: "/quizCategory/history-pyq-sscvidya", backgroundImage: "https://via.placeholder.com/300x200" },
    { id: 4, title: "Biology", description: "Revise essential biology topics and SSC MTS 2024 PYQs covering human anatomy, botany, and more.", link: "/quizCategory/biology-pyq-2024-sscvidya", backgroundImage: "https://via.placeholder.com/300x200" },
    { id: 5, title: "Chemistry", description: "Master chemical reactions, periodic table concepts, and SSC MTS 2024 previous year chemistry questions.", link: "/quizCategory/chemistry-pyq-2024-sscvidya", backgroundImage: "https://via.placeholder.com/300x200" },
    { id: 6, title: "Physics", description: "Prepare with physics PYQs from SSC MTS 2024, covering motion, electricity, and modern physics concepts.", link: "/quizCategory/physics-pyq-2024-sscvidya", backgroundImage: "https://via.placeholder.com/300x200" },
    { id: 7, title: "Current Affairs", description: "Stay updated with the latest current affairs and SSC MTS 2024 exam questions from national and international events.", link: "quizCategory/current-affairs-pyq-2024-sscvidya", backgroundImage: "https://via.placeholder.com/300x200" },
    { id: 8, title: "Static GK PYQ", description: "Brush up on static GK topics, including important facts and previous year SSC MTS 2024 questions.", link: "/quizCategory/static-gk-pyq", backgroundImage: "https://via.placeholder.com/300x200" },
    { id: 9, title: "Arts & Culture PYQ 2024", description: "Learn about India's art, culture, and heritage with previous year SSC MTS 2024 exam questions.", link: "/quizCategory/static-gk-pyq", backgroundImage: "https://via.placeholder.com/300x200" }
];


const cardDataSecond = [
    // {
    //     id: 1,
    //     title: "Topicwise SSC Quizzes",
    //     description: "Practice topic-wise quizzes for SSC exams.",
    //     image: "https://study.com/cimages/multimages/16/img_77358528836209341208499.jpg",
    //     link: "/quizCategory",
    // },
    // {
    //     id: 2,
    //     title: "SSC 2022 PYQ",
    //     description: "Practice SSC 2022 PYQ to boost your preparation.",
    //     image: "https://img.freepik.com/premium-vector/exam-concept-with-checklist-books-timer-hourglass-pencil_773186-439.jpg?w=1800",
    //     link: "/quizCategory/ssc-2022-pyq-sscvidya",
    // },
    // {
    //     id: 3,
    //     title: "Gharanas Previous Year Questions",
    //     description: "Solve past year papers for better understanding.",
    //     image: "https://img.freepik.com/free-vector/flat-vasant-panchami-festival-background_23-2149947770.jpg?t=st=1733546327~exp=1733549927~hmac=2d8cbbf970e49785d4bb0dc80b26f87ebe01101de9cef214f187bb2fb4277dbf&w=1800",
    //     link: "/quizCategory/gharanas-pyq-sscvidya",
    // },
    // {
    //     id: 4,
    //     title: "Mordern History Previous Year Questions",
    //     description: "Solve past year papers for better understanding.",
    //     image: "https://img.freepik.com/free-vector/watercolor-literature-illustration_52683-81540.jpg?t=st=1737198787~exp=1737202387~hmac=3f2bedf30c4848a175b7b30d9be463e44b0f184af2a85b516eb4dc652a9a9db0&w=1800",
    //     link: "/quizCategory/mordern-history-pyq-sscvidya",
    // },
    {
        id: 5,
        title: "Polity Previous Year Questions",
        description: "Solve past year papers for better understanding.",
        image: "https://img.freepik.com/free-vector/people-taking-part-political-event-flat-illustration_74855-16914.jpg?t=st=1733544188~exp=1733547788~hmac=38caed3f6c2448b74dc2222119d3a3fe0fe07e791b4b5b09e79f63aa4acdcf0d&w=1800",
        link: "/quizCategory/polity-pyq-sscvidya",
    },
    {
        id: 6,
        title: "Economics Previous Year Questions",
        description: "Solve past year papers for better understanding.",
        image: "https://img.freepik.com/free-vector/man-watching-video-about-financial-literacy-lockdown-male-person-sitting-gold-coins-flat-vector-illustration_74855-18428.jpg?t=st=1733937809~exp=1733941409~hmac=acb342181e1431c2862967f41cd694df9dc686ecc7e41727cf255cb7a502345d&w=1800",
        link: "/quizCategory/economics-pyq-2024-sscvidya",
    },
    {
        id: 7,
        title: "History SSCVIDYA Previous Year Questions",
        description: "Solve past year papers for better understanding.",
        image: "https://static.vecteezy.com/system/resources/thumbnails/001/759/739/small_2x/history-books-and-supplies-vector.jpg",
        link: "/quizCategory/history-pyq-sscvidya",
    },
    {
        id: 8,
        title: "Geography SSCVIDYA Previous Year Questions",
        description: "Solve past year geography questions for better understanding.",
        image: "https://img.freepik.com/premium-vector/geologist-illustration-with-soil-analysis-features-earth-science-research-expedition_2175-15283.jpg?w=1800",
        link: "/quizCategory/geography-pyq-ssc-questions-sscvidya",
    },
];

function HomePage() {

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div style={{ padding: '20px', minHeight: '100vh', overflowY: 'auto' }}>
            <Helmet>
                {/* Page Title */}
                <title>SSC Vidya - SSC Exam Preparation | CGL, CHSL, MTS Mock Tests</title>

                {/* Meta Description */}
                <meta
                    name="description"
                    content="Prepare for SSC exams with SSC Vidya. Access topicwise MCQs, mock tests, and quizzes for SSC CGL, CHSL, MTS, and RRB exams. Your SSC success starts here."
                />

                {/* Keywords */}
                <meta
                    name="keywords"
                    content="ssc, sscvidya, ssc vidya, ssc mock test, ssc quiz, ssc cgl mcqs, ssc preparation tips, ssc mts, rrb exams, topicwise mcqs, sscvidya.in"
                />

                {/* Open Graph Tags for Social Sharing */}
                <meta property="og:title" content="SSC Exam Preparation | Master Topicwise Quizzes" />
                <meta
                    property="og:description"
                    content="Attempt topicwise quizzes, PYQs, and mock tests for SSC CGL, CHSL, MTS, and RRB exams. Get the edge with SSC Vidya."
                />
                <meta property="og:image" content="/images/ssc-exam-preparation.png" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />

                {/* Canonical URL for SEO */}
                <link rel="canonical" href={window.location.href} />

                {/* Structured Data for SEO */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "name": "SSC Vidya",
                        "url": "https://sscvidya.in",
                        "logo": "https://sscvidya.in/logo.png",
                        "description": "SSC Vidya offers quizzes, topicwise MCQs, and mock tests for SSC CGL, CHSL, MTS, and RRB exams.",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://sscvidya.in/?q={search_term}",
                            "query-input": "required name=search_term"
                        },
                        "sameAs": [
                            "https://facebook.com/sscvidya",
                            "https://twitter.com/sscvidya",
                            "https://instagram.com/sscvidya"
                        ]
                    })}
                </script>
            </Helmet>

            {/* Slider Section */}
            <SliderSection sliderData={sliderData} />

            {/* Card Section */}
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
                <div className="t-heading" style={{ marginBottom: "20px" }}>
                    <span>Recently Added  </span>
                    <span>Questions Sets </span>
                    <span>Topic-Wise Questions for </span>
                    <span>CGL, MTS, CHSL</span>
                </div>
                {[...cardDataSecond].reverse().map((card) => (
                    <PreviewCard key={card.id} card={card} darkMode={darkMode} />
                ))}
            </div>

            {/* EXPLORE SECTION */}
            <ExploreSection />
            {/* Larger Cards Section */}
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', marginTop: "30px" }}>
                <div className="t-heading" style={{ marginBottom: "20px" }}>
                    <span>SSCVIDYA Most Popular </span>
                    <span>SSC MTS 2024 Exam Preparation </span>
                    <span>Subject-Wise Previous Year Questions</span>
                </div>
                {mts2024Data.map((card) => (
                    <InfoCard key={card.id} card={card} darkMode={darkMode} />
                ))}
            </div>
            {/* Larger Cards Section */}
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', marginTop: "30px" }}>
                <div className="t-heading" style={{ marginBottom: "20px" }}>
                    <span>SSCVIDYA Most Popular </span>
                    <span>Topic-Wise Questions Sets </span>
                    <span>CGL, MTS & CHSL </span>
                </div>
                {cardData.map((card) => (
                    <InfoCard key={card.id} card={card} darkMode={darkMode} />
                ))}
            </div>

        </div>
    );
}

export default HomePage;
