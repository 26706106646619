import React, { useState, useContext, useEffect } from 'react';
import Toggle from '../toggle/Toggle';
import './Navbar.css';
import Modal from 'react-modal'; // Import Modal
import telegramPath from "../../img/telegramPath.jpg";
// SCROLL
import { Link as ScrollLink } from 'react-scroll';

// ROUTE
import { Link as RouterLink, useLocation } from 'react-router-dom';

// Import icons for hamburger
import { FaBars, FaTimes } from 'react-icons/fa';

// Import your ThemeContext
import { themeContext } from '../../Context';

// Import Firebase
import { auth, } from '../../firebase/firebaseConfig'; // Ensure your Firebase config is in firebase.js
import { fetchUsers } from '../../firebase/firestoreFunctions';

Modal.setAppElement('#root');  // Or the ID of your root element


function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [userName, setUserName] = useState('Saurav'); // Default name
  const [isTelegramModalOpen, setTelegramModalOpen] = useState(false); // State for Telegram modal

  //USERS COUNT
  const [isUserModalOpen, setUserModalOpen] = useState(false); // State to control modal visibility
  // const [userCount, setUserCount] = useState(0); // State to store user count
  const [currentUserScore, setCurrentUserScore] = useState(0); // State to store user count

  const [users, setUsers] = useState([]); // State to store user list

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const handleToggleMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const location = useLocation(); // Hook to get current route

  // Check if current path is the homepage
  const isHomePage = location.pathname === '/founder';

  const modalStyle = {
    content: {
      width: "60vw",
      height: "60%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: darkMode ? "#2b2929" : "white",
      color: darkMode ? "white" : "",
      border: "none",
      // opacity: "0.9", // Opacity value (0.9 = 90% opacity)
    },
    overlay: {
      backgroundColor: "rgba(12, 12, 12, 0.5)" // Use rgba to set opacity for the overlay
    }
  };

  // Firebase Authentication Listener
  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     setIsLoggedIn(!!user); // Set true if user is logged in, false otherwise
  //   });

  //   // Cleanup the listener on component unmount
  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserName(user.displayName.split(' ')[0] || 'Anonymous');

        // // Only fetch users if there is an authenticated user
        // try {
        //   const usersData = await fetchUsers();
        //   // setUsers(usersData);
        //   setUserCount(usersData.length); // Set user count if needed
        // } catch (error) {
        //   console.error("Error fetching users:", error);
        // }

      } else {
        setIsLoggedIn(false);
        setUserName('Saurav'); // Default name when logged out
        // setUsers([]); // Clear users data when logged out
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);


  // Sign Out function
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setIsLoggedIn(false); // Update the state to reflect the user is signed out
      window.alert("Signed out successfully!"); // Alert on successful sign-out
    } catch (error) {
      console.error("Sign out failed", error);
    }
  };

  // Fetch user count and list on component mount
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const usersData = await fetchUsers();
  //       setUsers(usersData);
  //       setUserCount(usersData.length);
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  //THE BELOW CODE SHOW LIST OF ALL USERS ON SITE
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const usersData = await fetchUsers();

  //       // Process users to calculate total score
  //       const processedUsers = usersData.map(user => {
  //         let totalScore = 0;

  //         // Check if quizAttempts exists and is an object
  //         if (user.quizAttempts && typeof user.quizAttempts === 'object') {
  //           Object.values(user.quizAttempts).forEach(category => {
  //             // Check if category exists and is an object
  //             if (category && typeof category === 'object') {
  //               Object.values(category).forEach(attempt => {
  //                 // Check if attempt exists and has a score property
  //                 if (attempt && typeof attempt === 'object' && 'score' in attempt) {
  //                   totalScore += attempt.score || 0;
  //                 }
  //               });
  //             }
  //           });
  //         }

  //         return { ...user, totalScore };
  //       });

  //       // Sort users by totalScore (descending order)
  //       processedUsers.sort((a, b) => b.totalScore - a.totalScore);

  //       setUsers(processedUsers);
  //       setUserCount(processedUsers.length);
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  //THE BELOW CODE SHOWS LIST OF TOP 10 USERS AND CURRENT USER SCORE
  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersData = await fetchUsers();

        // Process users to calculate total score
        const processedUsers = usersData.map(user => {
          let totalScore = 0;

          // Check if quizAttempts exists and is an object
          if (user.quizAttempts && typeof user.quizAttempts === 'object') {
            Object.values(user.quizAttempts).forEach(category => {
              // Check if category exists and is an object
              if (category && typeof category === 'object') {
                Object.values(category).forEach(attempt => {
                  // Check if attempt exists and has a score property
                  if (attempt && typeof attempt === 'object' && 'score' in attempt) {
                    totalScore += attempt.score || 0;
                  }
                });
              }
            });
          }

          return { ...user, totalScore };
        });

        // Sort users by totalScore (descending order)
        processedUsers.sort((a, b) => b.totalScore - a.totalScore);

        // Get the top 10 users
        const top10Users = processedUsers.slice(0, 10);

        // Find the current user's score
        const currentUser = processedUsers.find(user => user.email === auth.currentUser?.email);
        const currentUserScore = currentUser ? currentUser.totalScore : 0;

        setUsers(top10Users);
        setCurrentUserScore(currentUserScore);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchData();
  }, []);



  function afterOpenModal() {
    document.body.style.overflow = 'hidden'; // Prevent background scroll
  }

  function afterCloseModal() {
    document.body.style.overflow = 'auto'; // Prevent background scroll
  }


  return (
    <div className="n-wrapper">
      <div className="n-left">
        <div className="n-name">{isHomePage ? 'Saurav' : isLoggedIn ? `${userName.slice(0, 7)}...` : 'SSCVIDYA'}</div>
        <Toggle />
      </div>
      <div className={`n-right ${isMobileMenuOpen ? 'open' : ''}`}>
        <div className="n-list" style={{ backgroundColor: darkMode ? '#000' : '' }}>
          <ul style={{ listStyle: 'none', color: darkMode ? '#fff' : '#000' }}>

            <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleToggleMenu}>
              <li>Home</li>
            </RouterLink>

            {/* <ScrollLink spy={true} to="Services" smooth={true} onClick={handleToggleMenu}>
              <li>Services</li>
            </ScrollLink>

            <ScrollLink spy={true} to="Portfolio" smooth={true} onClick={handleToggleMenu}>
              <li>Portfolio</li>
            </ScrollLink>

            <ScrollLink spy={true} to="Experience" smooth={true} onClick={handleToggleMenu}>
              <li>Experience</li>
            </ScrollLink>

            <ScrollLink spy={true} to="testimonial" smooth={true} onClick={handleToggleMenu}>
              <li>Testimonials</li>
            </ScrollLink> */}

            {isHomePage && (
              <>
                <ScrollLink spy={true} to="Services" smooth={true} onClick={handleToggleMenu} className="nav-item">
                  <li>Services</li>
                </ScrollLink>

                <ScrollLink spy={true} to="Portfolio" smooth={true} onClick={handleToggleMenu} className="nav-item">
                  <li>Portfolio</li>
                </ScrollLink>

                <ScrollLink spy={true} to="Experience" smooth={true} onClick={handleToggleMenu} className="nav-item">
                  <li>Experience</li>
                </ScrollLink>

                <ScrollLink spy={true} to="testimonial" smooth={true} onClick={handleToggleMenu} className="nav-item">
                  <li>Testimonials</li>
                </ScrollLink>
              </>
            )}


            <RouterLink to="/quizCategory" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleToggleMenu}>
              <li>Quizzes</li>
              <span style={{
                marginLeft: '8px',
                backgroundColor: '#FF6347', // Bright color for the "New" badge
                color: 'white',
                fontSize: '10px',
                padding: '2px 6px',
                borderRadius: '12px',
                fontWeight: 'bold',
              }}>
                New
              </span>
            </RouterLink>

            {isLoggedIn && !isHomePage && (
              <li
                // style={{ display: "none" }}//Comment This to get User Data
                onClick={() => {
                  setUserModalOpen(true);
                  handleToggleMenu();
                }}
              >
                Rank
              </li>
            )}

            <RouterLink to="/blogsCategory" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleToggleMenu}>
              <li>Blogs</li>
              <span style={{
                marginLeft: '8px',
                backgroundColor: '#FF6347', // Bright color for the "New" badge
                color: 'white',
                fontSize: '10px',
                padding: '2px 6px',
                borderRadius: '12px',
                fontWeight: 'bold',
              }}>
                New
              </span>
            </RouterLink>

            <RouterLink to="/downloads" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleToggleMenu}>
              <li>Downloads</li>
            </RouterLink>

            <RouterLink to="/ebooks" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleToggleMenu}>
              <li>Ebooks</li>
            </RouterLink>


            <li style={{ width: "maxContent" }} onClick={() => {
              setTelegramModalOpen(true); // Open Telegram modal
              handleToggleMenu();
            }}>Join Telegram</li>


          </ul>
        </div>

        {/* <ScrollLink to="contact" spy={true} smooth={true}>
          <button className="button n-button">Contact</button>
        </ScrollLink> */}



        {/* Conditional Button */}
        {isHomePage ? (
          <ScrollLink to="contact" spy={true} smooth={true}>
            <button className="button n-button">Contact</button>
          </ScrollLink>
        ) : isLoggedIn ? (
          <RouterLink style={{ textDecoration: 'none' }} onClick={handleSignOut}>
            <button className="button n-button" style={{ width: "max-content" }}>Sign Out</button>
          </RouterLink>
        ) : (
          <RouterLink to="/login" style={{ textDecoration: 'none' }}>
            <button className="button n-button">Login</button>
          </RouterLink>
        )}

      </div>

      {/* Hamburger Icon */}
      <div className="hamburger" onClick={handleToggleMenu}>
        {isMobileMenuOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {/* Modal for showing users list */}
      <Modal
        isOpen={isUserModalOpen}
        style={modalStyle}
        onAfterOpen={afterOpenModal}
        onAfterClose={afterCloseModal}
        onRequestClose={() => setUserModalOpen(false)}
        contentLabel="User List"
      >
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <h2>Your Total Points: {currentUserScore}</h2>
          <h2 style={{ marginBottom: "20px" }}>Current Top Users Scores</h2>
          <div style={{ flex: 1, overflowY: "auto" }}>
            {users.map((user, index) => {
              let rankingStyle = { color: "black", fontWeight: 'bold' };
              if (index === 0) rankingStyle = { color: 'gold', fontWeight: 'bold' };
              else if (index === 1) rankingStyle = { color: 'silver', fontWeight: 'bold' };
              else if (index === 2) rankingStyle = { color: '#cd7f32', fontWeight: 'bold' };

              const isCurrentUser = user.email === auth.currentUser?.email; // Highlight current user

              return (
                <div
                  key={user.id}
                  className="userItem"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: isCurrentUser ? '#dff0d8' : 'transparent', // Highlight current user
                    padding: '5px',
                    borderRadius: '5px'
                  }}
                >
                  <span style={{ marginRight: '10px', ...rankingStyle }}>
                    {index + 1}
                  </span>
                  {/* <img
                    src={user.photoURL || 'https://www.shutterstock.com/image-vector/default-avatar-profile-icon-vector-600nw-1745180411.jpg'}
                    alt={user.name || "Anonymous"}
                    className="userAvatar"
                    style={{ borderRadius: '50%', width: '40px', height: '40px' }}
                  /> */}
                  <span className="userName">
                    {user.name && user.name.length > 7 ? `${user.name.slice(0, 7)}...` : user.name || "Anonymous"}
                  </span>                  <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>
                    {user.totalScore} pts
                  </span>
                </div>
              );
            })}

          </div>
          <button
            className='button'
            style={{ marginTop: "40px", alignSelf: "center", width: "50vw" }}
            onClick={() => setUserModalOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal>

      {/* Modal for showing Telegram QR Code */}
      <Modal
        isOpen={isTelegramModalOpen}
        style={modalStyle}
        onAfterOpen={afterOpenModal}
        onAfterClose={afterCloseModal}
        onRequestClose={() => setTelegramModalOpen(false)}
        contentLabel="Join Telegram"
      >
        <div style={{ textAlign: "center", padding: "20px" }}>
          <h2 style={{ marginBottom: "20px" }}>Join Our Telegram Channel</h2>
          <img
            src={telegramPath} // Replace with the actual path to your QR code image
            alt="Telegram QR Code"
            style={{ maxWidth: "50vw", height: "20vh", marginBottom: "20px" }}
          />
          <p>
            Or click <a href="https://t.me/+WBQKdkviPyNjNmRl" target="_blank" rel="noopener noreferrer" style={{ color: "blue", textDecoration: "underline" }}>here</a> to join directly!
          </p>
          <button
            className="button"
            style={{ marginTop: "20px" }}
            onClick={() => setTelegramModalOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Navbar;
