import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { themeContext } from "../../Context";

const PreviewCard = ({ card, price, addToCart }) => {
    const [isAdded, setIsAdded] = useState(false); // Track if item is added to cart

    const navigate = useNavigate();
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div
            onClick={() => navigate(card.link)}
            style={{
                cursor: 'pointer',
                border: '2px solid #ccc',
                borderRadius: '10px',
                width: 'clamp(180px, 80vw, 280px)',
                overflow: 'hidden',
                background: darkMode ? "black" : "white",
                transition: 'transform 0.3s, box-shadow 0.3s',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            }}
        >
            {/* Card Image */}
            <img
                src={card.image}
                alt={card.title}
                style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderBottom: '1px solid #ccc',
                }}
            />

            {/* Card Content */}
            <div style={{ padding: '15px' }}>
                <span style={{ fontWeight: "bold", color: darkMode ? "white" : '#333' }}>
                    {card.title}
                </span>
                <p style={{ color: darkMode ? "white" : "#666", fontSize: '14px', marginTop: '8px' }}>
                    {card.description}
                </p>
                {price !== undefined && (
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                        <div style={{ fontWeight: "bold", color: darkMode ? "lightgray" : "#333" }}>
                            Price: Rs. {price}
                        </div>
                        <div>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevents navigation trigger
                                    addToCart(card);
                                    setIsAdded(true); // Mark item as added
                                }}
                                className="button n-button"
                            // style={{
                            //     backgroundColor: "blue",
                            //     color: "white",
                            //     padding: "5px 10px",
                            //     border: "none",
                            //     borderRadius: "5px",
                            //     cursor: "pointer",
                            // }}
                            >
                                {isAdded ? "Added" : "Add to Cart"}
                            </button>
                        </div>

                    </div>
                )}
            </div>
        </div>
    );
};

export default PreviewCard;
