import React from "react";

const TermsAndConditions = () => {
    const styles = {
        container: {
            maxWidth: "800px",
            margin: "20px auto",
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            lineHeight: "1.6",
            color: "#333",
            border: "1px solid #ddd",
            borderRadius: "5px",
            backgroundColor: "#f9f9f9",
        },
        heading: {
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "10px",
            color: "#444",
        },
        subHeading: {
            fontSize: "14px",
            fontStyle: "italic",
            color: "#666",
            marginBottom: "15px",
        },
        sectionTitle: {
            fontSize: "18px",
            fontWeight: "bold",
            marginTop: "15px",
            color: "#222",
        },
        paragraph: {
            fontSize: "14px",
            marginBottom: "10px",
            textAlign: "justify",
        },
        list: {
            marginLeft: "20px",
        },
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Terms & Conditions</h1>
            <p style={styles.subHeading}>Last updated on 22-02-2025 13:00:55</p>

            <p style={styles.paragraph}>
                These Terms and Conditions, along with the Privacy Policy or other terms (“Terms”)
                constitute a binding agreement by and between <strong>SUMAN SAURAV</strong>
                (“Website Owner” or “we” or “us” or “our”) and you (“you” or “your”)
                and relate to your use of our website, goods, or services (collectively, “Services”).
            </p>

            <p style={styles.paragraph}>
                By using our website and availing the Services, you agree that you have read and accepted these Terms
                (including the Privacy Policy). We reserve the right to modify these Terms at any time and without
                assigning any reason. It is your responsibility to periodically review these Terms to stay informed of
                updates.
            </p>

            <h2 style={styles.sectionTitle}>Terms of Use:</h2>
            <ul style={styles.list}>
                <li style={styles.paragraph}>
                    You agree to provide true, accurate, and complete information during and after registration.
                </li>
                <li style={styles.paragraph}>
                    Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
                    performance, or completeness of the information provided on this website.
                </li>
                <li style={styles.paragraph}>
                    Your use of our Services is solely at your own risk and discretion.
                </li>
                <li style={styles.paragraph}>
                    The contents of the Website and Services are proprietary, and you will not claim any intellectual property rights.
                </li>
                <li style={styles.paragraph}>
                    Unauthorized use of the Website or Services may lead to legal action.
                </li>
                <li style={styles.paragraph}>
                    You agree to pay us the charges associated with availing the Services.
                </li>
                <li style={styles.paragraph}>
                    You shall not use the website or Services for any unlawful or illegal purpose.
                </li>
                <li style={styles.paragraph}>
                    The website and Services may contain links to third-party websites, and their terms will apply when accessing them.
                </li>
                <li style={styles.paragraph}>
                    Upon initiating a transaction for availing the Services, you enter into a legally binding contract with us.
                </li>
                <li style={styles.paragraph}>
                    Refund claims must be raised within the stipulated time to be eligible.
                </li>
                <li style={styles.paragraph}>
                    We shall not be liable for any failure to perform obligations due to a force majeure event.
                </li>
            </ul>

            <h2 style={styles.sectionTitle}>Governing Law & Disputes:</h2>
            <p style={styles.paragraph}>
                These Terms shall be governed by the laws of India. Any disputes shall be subject to the exclusive
                jurisdiction of the courts in Piru, Bihar.
            </p>

            <h2 style={styles.sectionTitle}>Contact Information:</h2>
            <p style={styles.paragraph}>
                For any concerns regarding these Terms, please contact us using the details provided on our website.
            </p>
        </div>
    );
};

export default TermsAndConditions;
