import React, { useState, useEffect, useCallback } from 'react';

const MathPowerGame = () => {
    const [mode, setMode] = useState('squares'); // 'squares' or 'cubes'
    const [score, setScore] = useState(0);
    const [currentNumber, setCurrentNumber] = useState(0);
    const [userAnswer, setUserAnswer] = useState('');
    const [feedback, setFeedback] = useState('');
    const [isCorrect, setIsCorrect] = useState(null);

    // Generate a random number (1-12 for squares, 1-10 for cubes)
    // const generateQuestion = () => {
    //     const max = mode === 'squares' ? 20 : 20;
    //     const num = Math.floor(Math.random() * max) + 1;
    //     setCurrentNumber(num);
    //     setUserAnswer('');
    //     setFeedback('');
    //     setIsCorrect(null);
    // };
    const generateQuestion = useCallback(() => {
        const max = mode === 'squares' ? 20 : 20;
        const num = Math.floor(Math.random() * max) + 1;
        setCurrentNumber(num);
        setUserAnswer('');
        setFeedback('');
        setIsCorrect(null);
    }, [mode]);  // Now stable between renders

    // Check the user's answer
    const checkAnswer = () => {
        const correctAnswer = mode === 'squares'
            ? currentNumber * currentNumber
            : currentNumber * currentNumber * currentNumber;

        if (parseInt(userAnswer) === correctAnswer) {
            setScore(score + 1);
            setFeedback('✅ Correct!');
            setIsCorrect(true);
        } else {
            setFeedback(`❌ Wrong! Correct answer: ${correctAnswer}`);
            setIsCorrect(false);
        }
        setTimeout(generateQuestion, 1500); // Next question after 1.5s
    };

    // Start a new game
    useEffect(() => {
        generateQuestion();
    }, [mode, generateQuestion]);

    return (
        <div style={{
            textAlign: 'center',
            maxWidth: '70vh',
            margin: '0 auto',
            minHeight: 'calc(100vh - 340px)',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
            borderRadius: '16px',
            boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
        }}>
            <h1 style={{
                fontSize: 'clamp(1.5rem, 2vw, 1.5rem)',
                color: '#2c3e50',
                marginBottom: '30px',
                textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
            }}>
                Math Power Challenge ⚡
            </h1>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                marginBottom: '30px',
                flexWrap: 'wrap'
            }}>
                <button
                    onClick={() => setMode('squares')}
                    style={{
                        backgroundColor: mode === 'squares' ? '#4CAF50' : '#e0e0e0',
                        color: mode === 'squares' ? 'white' : '#333',
                        padding: '12px 24px',
                        border: 'none',
                        borderRadius: '50px',
                        cursor: 'pointer',
                        fontSize: 'clamp(0.9rem, 3vw, 1rem)',
                        fontWeight: '600',
                        transition: 'all 0.3s ease',
                        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                        flex: '1 1 auto',
                        minWidth: '120px'
                    }}
                >
                    Squares (1²-20²)
                </button>
                <button
                    onClick={() => setMode('cubes')}
                    style={{
                        backgroundColor: mode === 'cubes' ? '#4CAF50' : '#e0e0e0',
                        color: mode === 'cubes' ? 'white' : '#333',
                        padding: '12px 24px',
                        border: 'none',
                        borderRadius: '50px',
                        cursor: 'pointer',
                        fontSize: 'clamp(0.9rem, 3vw, 1rem)',
                        fontWeight: '600',
                        transition: 'all 0.3s ease',
                        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                        flex: '1 1 auto',
                        minWidth: '120px'
                    }}
                >
                    Cubes (1³-20³)
                </button>
            </div>

            <div key={currentNumber} style={{
                fontSize: 'clamp(1.5rem, 2vw, 1.5rem)',
                margin: '20px 0',
                color: isCorrect === true ? '#27ae60' : isCorrect === false ? '#e74c3c' : '#2c3e50',
                fontWeight: '700',
                transition: 'color 0.3s ease'
            }}>
                What is {currentNumber}{mode === 'squares' ? '²' : '³'}?
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '15px',
                marginBottom: '20px'
            }}>
                <input
                    type="number"
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && checkAnswer()}
                    style={{
                        padding: '15px',
                        fontSize: '1.2rem',
                        width: '150px',
                        textAlign: 'center',
                        borderRadius: '8px',
                        border: '2px solid #dfe6e9',
                        outline: 'none',
                        transition: 'all 0.3s ease',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        maxWidth: '80%'
                    }}
                    placeholder="Answer"
                />

                <button
                    onClick={checkAnswer}
                    style={{
                        backgroundColor: '#007BFF',
                        color: 'white',
                        padding: '12px 30px',
                        border: 'none',
                        borderRadius: '50px',
                        cursor: 'pointer',
                        fontSize: '1.1rem',
                        fontWeight: '600',
                        transition: 'all 0.3s ease',
                        boxShadow: '0 4px 8px rgba(0,123,255,0.3)',
                        width: 'fit-content'
                    }}
                >
                    Submit
                </button>
            </div>

            <div style={{
                fontSize: '1.3rem',
                margin: '15px 0',
                minHeight: '30px',
                fontWeight: '500',
                color: isCorrect === true ? '#27ae60' : isCorrect === false ? '#e74c3c' : 'transparent',
                transition: 'all 0.3s ease'
            }}>
                {feedback}
            </div>

            <div style={{
                // marginTop: 'auto',
                fontSize: '1.2rem',
                color: '#2c3e50',
                fontWeight: '600',
                padding: '15px',
                backgroundColor: 'rgba(255,255,255,0.7)',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
            }}>
                Score: <strong style={{ fontSize: '1.4rem' }}>{score}</strong>
            </div>
        </div>
    );
};

export default MathPowerGame;